import { useMutation } from '@apollo/client';
import { graphql } from '../../gql';

const UPLOAD_PUBLIC_IMAGE = graphql(`
  mutation uploadPublicImage($file: Upload!) {
    uploadPublicImage(file: $file)
  }
`)

const useUploadPublicImage = () => {
  return useMutation(UPLOAD_PUBLIC_IMAGE);
};

export default useUploadPublicImage;
