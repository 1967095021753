import React, { createContext, useContext, useEffect, useState } from 'react';
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { useAuth, useMe } from '../../hooks';
import { Suspense } from 'react';
import { storeRequestedLocation } from '../../utils/locationRestore';
import { getRoom, saveInviteCode, useRoomNavigate } from '../../utils';
import { changeLanguage } from "i18next"
import { B4BackgroundDark, B4Layout, B4SpaceHorizontal, B4SpaceVertical } from '../../design/atomic/layout';
import { B4BackgroundSwarm } from '../../design/atomic/animation';
import { B4Modal } from '../../design/atomic/modal';
import { B4Bubble, B4Corner } from '../../design/atomic/bubble';
import { useForm } from 'react-hook-form';
import { B4ControllerTextInput } from '../../design/atomic/input/text';
import { B4Button, B4IconButton } from '../../design/atomic/button';
import { MdFlashOn } from 'react-icons/md';
import { B4CheckBox } from '../../design/atomic/input/checkbox';
import { B4ControllerRichTextInput } from '../../design/atomic/input/rich-text';
import { B4TextMedium } from '../../design/atomic/text';
import { B4Color } from '../../design/atomic/consts';
import { B4BackgroundContext, B4BackgroundType, useAltKeyPress } from '../../design/atomic/utils';

export const GuestRoute = ({children}) => {
  const { loading, data } = useAuth();
  const navigate = useRoomNavigate()

  if (loading || !data) return null;

  if (data.isLoggedIn) {
    setTimeout(() => navigate('/dashboard'))
  }

  return <Suspense fallback={<B4Layout loading />}>{children}</Suspense>;
};

export const GuestRouteIgnoreLoggedIn = ({children}) => <Suspense fallback={<B4Layout loading />}>{children}</Suspense>

export const UserRoute = ({children}) => {
  const { loading, data } = useAuth();
  const { data: dataMe } = useMe({fetchPolicy: 'network-only'})
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (dataMe) {
      changeLanguage(dataMe.me.language) // adopt language if me data is available
    }
  }, [dataMe])

  if (loading || !data) return null;

  if (!data.isLoggedIn) {
    const inviteCode = searchParams.get('code')
    if (inviteCode) {
      saveInviteCode(inviteCode)
    }
    storeRequestedLocation(location)
    setTimeout(() => navigate('/landing'))
  }

  return <Suspense fallback={<B4Layout loading />}>{children}</Suspense>;
};

export const RoomRoute = ({children}) => {
  const navigate = useNavigate()

  if (!getRoom()) {
    setTimeout(() => navigate('/landing'))
  }

  return <Suspense fallback={<B4Layout />}>{children}</Suspense>;
};

export const I18nContext = createContext({
  setI18nKey: (i18nKey: string) => {},
  i18nKey: null,
  isAlt: false
});
export const useI18nContext = () => useContext(I18nContext);

const I18N_LANGUAGES = ['en', 'de', 'fr', 'it', 'es', 'pt', 'sv']
const I18N_LANGUAGES_FORM_DEFAULTS =  I18N_LANGUAGES.reduce((acc, lang) => ({...acc, [lang]: ''}), {})
type I18nFormValues = Record<typeof I18N_LANGUAGES[number], string>;

const B4RouteI18nModal = ({i18nKey, onClose}) => {
  const [data, setData] = useState(null);
  const [richText, setRichText] = useState(false);
  useEffect(() => {
    setData({})
    if (i18nKey) {
      fetch(`http://localhost:3001/translations/${i18nKey}`)
        .then(response => response.json())
        .then((data: {[key: string]: string}) => {
          if (Object.values(data).some(value => value.includes('<'))) {
            setRichText(true)
          }
          setData(data);
        })
    }
  }, [i18nKey])

  const { control, handleSubmit, reset, formState: {isDirty}, getValues, setValue } = useForm<I18nFormValues>({
    defaultValues: I18N_LANGUAGES_FORM_DEFAULTS
  })

  useEffect(() => {
    if (data) {
      reset({
        ...I18N_LANGUAGES_FORM_DEFAULTS,
        ...data
      });
    }
  }, [data])

  const onSubmit = async data => {
    await fetch(`http://localhost:3001/translations/${i18nKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    onClose()
  }

  const onTranslate = async lang => {
    const response = await fetch(`http://localhost:3001/translate/${lang}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getValues())
    })

    const result = await response.json()

    Object.keys(result).forEach((key: string) => {
      setValue(key, result[key], {
        shouldDirty: true
      })
    })
  }

  return (
    <B4Modal open={!!i18nKey} onClose={onClose}>
      <B4Bubble sharpCorner={B4Corner.NONE}>
        <B4SpaceVertical>
          <B4TextMedium className="text-center">«{i18nKey}»</B4TextMedium>
          <B4CheckBox value={richText} onClick={() => setRichText(!richText)} label="Rich Text" />
          {
            I18N_LANGUAGES.map(lang => (<div className="flex gap-b4-std items-end" key={lang}>
              <div className="grow">
                {richText ? <B4ControllerRichTextInput key={lang} name={lang} label={lang} control={control} /> : <B4ControllerTextInput multiline rows={2} key={lang} name={lang} label={lang} control={control} /> }
              </div>
              <div className="py-2">
                <B4IconButton onClick={() => onTranslate(lang)}><MdFlashOn className="text-b4-orange" /></B4IconButton>
              </div>
            </div>))
          }
          <B4SpaceHorizontal>
            <B4Button color={B4Color.GREY} onClick={onClose}>Cancel</B4Button>
            <B4Button disabled={!isDirty} onClick={handleSubmit(onSubmit)}>Save</B4Button>
          </B4SpaceHorizontal>
        </B4SpaceVertical>
      </B4Bubble>
    </B4Modal>
  )
}

const I18nWrapper = () => {
  const [i18nKey, setI18nKey] = useState(null);
  const isAlt = useAltKeyPress()
  const isDevelopment = process.env.NODE_ENV === 'development'

  return (<>
    { isDevelopment &&
      <I18nContext.Provider value={{ setI18nKey, i18nKey, isAlt }}>
        <Outlet />
        <B4RouteI18nModal i18nKey={i18nKey} onClose={() => setI18nKey(null)} />
      </I18nContext.Provider>
    }
    {
      !isDevelopment && <Outlet />
    }
  </>)
}

export const BackgroundDarkWrapper = ({swarm = false}) => {


  return (
    <>
      {swarm && <B4BackgroundSwarm />}
      <B4BackgroundDark />
      <I18nWrapper />
    </>
  )
}

export const BackgroundLightWrapper = () => (
  <B4BackgroundContext.Provider value={{bgType: B4BackgroundType.LIGHT}}>
    <I18nWrapper />
  </B4BackgroundContext.Provider>
)
