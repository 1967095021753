import { useTranslation } from "react-i18next"
import { B4Color } from "../../design/atomic/consts"
import { B4Text, B4TextEmp, B4TextSmall, B4TextTiny } from "../../design/atomic/text"
import { B4NormalDistHorizontal, B4NormalDistType, B4RulerHorizontal, B4RulerVertical } from "../../design/atomic/layout"
import { AnswerQuickEvaluation, AnswerStatisticalEvaluation } from "../../gql/graphql"
import { Fragment } from "react"
import { range } from "lodash"
import { B4GridColumnType, B4Table, B4TableData, B4TableHeader, B4TableRow } from "../../design/atomic/table"
import { useRoomNavigate } from "../../utils"
import { useIsBig } from "../../design/atomic/utils"
import { GlobalUserType, useComputeGlobalUserType } from "../../design/atomic/question"
import clsx from "clsx"

const Scale = ({ top, range }: { top: boolean, range: number[] }) => (
  <div>
    {!top && <B4RulerHorizontal />}
    <B4NormalDistHorizontal elements={range.map(step => <B4TextSmall>{step}</B4TextSmall>)} type={B4NormalDistType.EDGES_INCLUDED} />
    {top && <B4RulerHorizontal />}
  </div>
)

interface IdeasGraphBaseProps<T> {
  answerStats: (T)[],
  getScore: (answerStat: T) => number,
  genBars: (answerStat: T) => JSX.Element,
  getRank: (answerStat: T) => number
}

const IdeaGraphBaseGraphTitle = ({ className = null }) => {
  return (
    <div className={className}>
      <B4NormalDistHorizontal className="gap-b4-std-1/2" type={B4NormalDistType.NO_EDGES} elements={[
        { label: 'txtBad', style: 'sepia(50%) hue-rotate(-40deg) brightness(90%)' },
        { label: 'txtUnsatisfactory', style: 'sepia(50%) hue-rotate(-40deg)' },
        { label: 'txtAcceptable', style: 'sepia(50%) hue-rotate(0deg)' },
        { label: 'txtGood', style: 'sepia(50%) hue-rotate(70deg)' },
        { label: 'txtPerfect', style: 'sepia(50%) hue-rotate(70deg) brightness(90%)' },
      ].map(item => <B4TextSmall className="text-center self-end" t={item.label} style={{ "WebkitFilter": item.style }} />)} />
    </div>
  )
}

const IdeasGraphBaseSmall = <T extends AnswerStatisticalEvaluation | AnswerQuickEvaluation>({ answerStats, getScore, genBars, getRank }: IdeasGraphBaseProps<T>) => {
  const { t } = useTranslation()
  return (
    <div className="space-y-b4-std-1/2">
      <IdeaGraphBaseGraphTitle />
      <div>
        <Scale top range={range(0, 101, 20)} />
        <div className="relative pt-b4-std">
          <div className="absolute inset-0">
            <B4NormalDistHorizontal className="h-full" elements={[...Array(4)].map(() => <B4RulerVertical thin />)} />
          </div>
          <div className={clsx('grid gap-x-b4-std gap-y-b4-std-1/2', {
            'grid-cols-[auto_1fr_auto]': getRank,
            'grid-cols-[1fr_auto]': !getRank
          })}>
            {!!getRank && <B4TextEmp className="z-[1] text-center self-end">{t('txtRank')}</B4TextEmp>}
            <B4TextEmp className="z-[1] self-end">{t('lblIdea')}</B4TextEmp>
            <B4TextTiny className="z-[1] font-bold text-center self-end" color={B4Color.BLUE_LIGHT} html={t('lblScore')} />
            {
              answerStats?.map((answerStat, i) => (
                <Fragment key={i}>
                  {getRank && <B4TextSmall className="font-semibold text-center">{getRank(answerStat)}</B4TextSmall>}
                  <div className="space-y-b4-std-1/2 z-[1]">
                    <B4TextSmall className="z-[1]">{answerStat.answer}</B4TextSmall>
                    {answerStat?.is_seed && <B4TextTiny color={B4Color.BLUE_LIGHT} html={t('lblSeedIdea')} />}
                  </div>
                  <B4TextSmall className="text-center font-semibold">{getScore(answerStat)}</B4TextSmall>
                  <div className="col-span-full h-b4-std-1/2 relative z-[1]">
                    {genBars(answerStat)}
                    <B4RulerHorizontal thin className="absolute left-0 right-0 bottom-0" />
                  </div>
                </Fragment>
              ))
            }
          </div>
        </div>
      </div>
      <Scale top={false} range={range(0, 101, 20)} />
    </div>
  )
}


const IdeasGraphBaseBig = <T extends AnswerStatisticalEvaluation | AnswerQuickEvaluation>({ answerStats, getScore, genBars, getRank }: IdeasGraphBaseProps<T>) => {
  return (
    <B4Table
    columns={[!!getRank ? B4GridColumnType.AUTO : undefined, B4GridColumnType.STRETCH, B4GridColumnType.AUTO, B4GridColumnType.STRETCH].filter(c => c !== undefined)}
    headerRows={[
      <B4TableRow>
        { !!getRank && <B4TableHeader><B4TextEmp noWordBreak className="text-center self-end" t="txtRank" /></B4TableHeader> }
        <B4TableHeader><B4TextEmp noWordBreak className="self-end" t="lblIdea" /></B4TableHeader>
        <B4TableHeader><B4TextTiny noWordBreak className="text-center font-bold self-end" color={B4Color.BLUE_LIGHT} t="lblScore" /></B4TableHeader>
        <B4TableHeader><IdeaGraphBaseGraphTitle className="self-end" /></B4TableHeader>
      </B4TableRow>,
      <B4TableRow>
        <B4TableHeader colSpan={getRank ? 4 : 3}><B4RulerHorizontal color={B4Color.BLUE_DARK} /></B4TableHeader>
      </B4TableRow>,
      <B4TableRow>
        <B4TableHeader colSpan={getRank ? 3 : 2}></B4TableHeader>
        <B4TableHeader><Scale top range={range(0, 101, 20)} /></B4TableHeader>
      </B4TableRow>,
    ]}
    rows={
      answerStats.flatMap((answerStat, i) => [
        <B4TableRow>
          { !!getRank && <B4TableData><B4Text className="text-center font-semibold">{answerStat.rank}</B4Text></B4TableData> }
          <B4TableData><div className="space-y-b4-std-1/2">
            <B4TextSmall className="z-[1]">{answerStat.answer}</B4TextSmall>
            {answerStat?.is_seed && <B4TextTiny color={B4Color.BLUE_LIGHT} t="lblSeedIdea" />}
          </div></B4TableData>
          <B4TableData><B4Text className="text-center font-semibold">{getScore(answerStat)}</B4Text></B4TableData>
          <B4TableData className="relative">
            <div className="flex items-center h-full">
              <div className="h-b4-std-2 relative grow">{genBars(answerStat)}</div>
            </div>
            <B4NormalDistHorizontal className="absolute inset-0" elements={[...Array(4)].map(_ => <B4RulerVertical thin className="-my-b4-std-3/8" />)} />
          </B4TableData>
        </B4TableRow>,
        <B4TableRow>
          <B4TableData colSpan={getRank ? 3 : 2}><B4RulerHorizontal color={B4Color.BLUE_DARK} /></B4TableData>
          <B4TableData><B4RulerHorizontal thin color={B4Color.GREY} /></B4TableData>
        </B4TableRow>
      ])
    } 
    footerRows={[
      <B4TableRow>
        <B4TableHeader colSpan={getRank ? 3 : 2}></B4TableHeader>
        <B4TableHeader><Scale top={false} range={range(0, 101, 20)} /></B4TableHeader>
      </B4TableRow>
    ]}/>
  )
}

const IdeasGraphBase = <T extends AnswerStatisticalEvaluation | AnswerQuickEvaluation>({ answerStats, getScore, genBars, getRank }: IdeasGraphBaseProps<T>) => {
  const isBig = useIsBig()

  if (isBig) {
    return <IdeasGraphBaseBig answerStats={answerStats} getScore={getScore} genBars={genBars} getRank={getRank} />
  }
  return <IdeasGraphBaseSmall answerStats={answerStats} getScore={getScore} genBars={genBars} getRank={getRank} />
}

export const PriorityRankingGraph = ({ answerStatisticalEvaluation, conversationId }) => {
  const roomNavigate = useRoomNavigate()
  const computeGlobalUserType = useComputeGlobalUserType()
  const isSystemAdmin = computeGlobalUserType() === GlobalUserType.SYSTEM_ADMIN
  const isBig = useIsBig()
  const getScore = (answerStat: AnswerStatisticalEvaluation) => Math.round(answerStat.median)
  const getRank = (answerStat: AnswerStatisticalEvaluation) => answerStat.rank
  const genBarStyle = (answerStat: AnswerStatisticalEvaluation) => {
    return {
      left: `${answerStat.median - 10}%`,
      right: `${110 - answerStat.median}%`,
      zIndex: 2
    }
  }
  return <IdeasGraphBase<AnswerStatisticalEvaluation> answerStats={answerStatisticalEvaluation}
    getRank={getRank}
    getScore={getScore}
    genBars={answerStat => {
      return <>
        <div className={clsx('absolute rounded-full ' + genGraphBarBaseColorClass(getScore(answerStat)), {
          'w-b4-std h-b4-std translate-y-b4-std-1/2 -translate-x-b4-std-1/2': isBig,
          'w-b4-std-1/2 h-b4-std-1/2 -translate-x-b4-std-1/4': !isBig
          })} style={{ left: `${answerStat.median}%`, zIndex: 3 }} />
        <div className={clsx('absolute top-0 bottom-0 transparent brightness-125', {
            'cursor-pointer active:opacity-50': isSystemAdmin,
          })}
          onClick={isSystemAdmin ? () => roomNavigate(`/conversation/${conversationId}/idea/${answerStat.answer_id}`) : undefined}
          style={genBarStyle(answerStat)} />
      </>
    }}
  />
}

export const PolarityGraph = ({ answerStatisticalEvaluation, conversationId }) => {
  const roomNavigate = useRoomNavigate()
  const computeGlobalUserType = useComputeGlobalUserType()
  const isSystemAdmin = computeGlobalUserType() === GlobalUserType.SYSTEM_ADMIN

  const getScore = (answerStat: AnswerStatisticalEvaluation) => Math.round(answerStat.median)
  const getRank = (answerStat: AnswerStatisticalEvaluation) => answerStat.rank
  const genBarClass = (answerStat: AnswerStatisticalEvaluation) => {
    return 'absolute top-0 bottom-0 rounded-lg border border-opacity-50 ' + genGraphBarBaseGradientColorClass(getScore(answerStat))
  }
  const genBarStyle = (answerStat: AnswerStatisticalEvaluation) => {
    return {
      left: `${Math.max(-1, answerStat.median - answerStat.sigma)}%`,
      right: `${100 - Math.min(100, answerStat.median + answerStat.sigma)}%`,
      zIndex: 2
    }
  }
  return <IdeasGraphBase<AnswerStatisticalEvaluation> answerStats={answerStatisticalEvaluation}
    getRank={getRank}
    getScore={getScore}
    genBars={answerStat => {
      return <>
        <div className="absolute top-0 bottom-0 bg-neutral-800 w-0.5 -translate-b4-std-1/8" style={{ left: `${answerStat.median}%`, zIndex: 3 }} />
        <div className={clsx(genBarClass(answerStat) + ' brightness-125', {
            'cursor-pointer active:opacity-50': isSystemAdmin,
          })}
          onClick={isSystemAdmin ? () => roomNavigate(`/conversation/${conversationId}/idea/${answerStat.answer_id}`) : undefined}
          style={genBarStyle(answerStat)} />
      </>
    }}
  />
}

export const CredibleIntervalGraph = ({ answerStatisticalEvaluation, conversationId }) => {
  const roomNavigate = useRoomNavigate()
  const computeGlobalUserType = useComputeGlobalUserType()
  const isSystemAdmin = computeGlobalUserType() === GlobalUserType.SYSTEM_ADMIN

  const getScore = (answerStat: AnswerStatisticalEvaluation) => Math.round(answerStat.median)
  const getRank = (answerStat: AnswerStatisticalEvaluation) => answerStat.rank
  const genBarStyle = (answerStat: AnswerStatisticalEvaluation) => {
    return {
      left: `${Math.max(0, answerStat.median - answerStat.credible_interval)}%`,
      right: `${100 - Math.min(100, answerStat.median + answerStat.credible_interval)}%`,
      zIndex: 2
    }
  }
  return <IdeasGraphBase<AnswerStatisticalEvaluation> answerStats={answerStatisticalEvaluation}
    getRank={getRank}
    getScore={getScore}
    genBars={answerStat => {
      return <>
        <div className="absolute top-0 bottom-0 bg-neutral-800 w-0.5 -translate-b4-std-1/8" style={{ left: `${answerStat.median}%`, zIndex: 3 }} />
        <div className={clsx('absolute top-0 bottom-0 bg-gradient-to-r from-neutral-300 via-neutral-600 to-neutral-300 opacity-20 rounded-lg border border-neutral-900 brightness-125', {
            'cursor-pointer active:opacity-50': isSystemAdmin,
          })}
          onClick={isSystemAdmin ? () => roomNavigate(`/conversation/${conversationId}/idea/${answerStat.answer_id}`) : undefined}
          style={genBarStyle( answerStat)} />
      </>
    }}
  />
}

const genGraphBarBaseColorClass = (score: number) => (
  score >= 80 ? 'bg-[#2E8B57]' :
    score >= 60 ? 'bg-[#43CD80]' :
      score >= 40 ? 'bg-[#FFD700]' :
        score >= 20 ? 'bg-[#FFA500]' :
          'bg-[#FF7256]')

const genGraphBarBaseGradientColorClass = (score: number) => (
  score >= 80 ? 'bg-gradient-to-r from-[#297341] to-[#2E8B57] border-[#1b5233]' :
    score >= 60 ? 'bg-gradient-to-r from-[#36a868] to-[#43CD80] border-[#297341]' :
      score >= 40 ? 'bg-gradient-to-r from-[#ccac00] to-[#FFD700] border-[#947012]' :
        score >= 20 ? 'bg-gradient-to-r from-[#d48900] to-[#FFA500] border-[#a16800]' :
          'bg-gradient-to-r from-[#d15c45] to-[#FF7256] border-[#783528]')

export const IdeasGraphQuick = ({ answerQuickEvaluation, dotted = false, noRank = false }) => {
  const getRank = noRank ? null : (answerStat: AnswerQuickEvaluation) => answerStat.rank
  return (
    <IdeasGraphBase<AnswerQuickEvaluation> answerStats={answerQuickEvaluation}
      getRank={getRank}
      getScore={answerStat => Math.round(answerStat.naive_score)}
      genBars={answerStat => <div className="absolute top-0 bottom-0 w-b4-std-1/4 -translate-b4-std-1/8" style={{
        left: `${answerStat.naive_score}%`,
        zIndex: 3,
        background: dotted ? `repeating-linear-gradient(
          0deg,
          #2F327F,
          #2F327F 0.250rem,
          #ffffff00 0.250rem,
          #ffffff00 0.375rem
        )` : '#2F327F'
      }}></div>}
    />
  )
}