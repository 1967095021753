import { useQuery } from '@apollo/client';
import { graphql } from '../../gql';

export const TENANTS_QUERY = graphql(/* GraphQL */ `
  query tenants($tenant_id: ID) {
    tenants(id: $tenant_id) {
      id
      image
      about_heading
      about_body
      teaser
      customer_name
      invite_code
      expired
      expire_at
    }
  }
`)

const useTenants = (options = {}) => {
  return useQuery(TENANTS_QUERY, options);
};

export default useTenants;
