import { MdLightbulbOutline, MdOutlineVideogameAsset, MdPeopleOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { B4Bubble, B4Corner } from "./bubble";
import { B4SpaceHorizontal } from "./layout";
import { B4Chip } from "./chip";
import { differenceInDays, endOfDay, endOfToday } from "date-fns";
import clsx from "clsx";
import { B4BubbleList, B4BubbleListFooterElement, B4BubbleListFooterElementProps } from "./grid";
import { useRoomNavigate } from "../../utils";
import { useMe } from "../../hooks";
import { orderBy } from "lodash";
import { formatDate } from "./utils";
import { B4Text, B4TextSup, B4TextTiny } from "./text";
import { B4Color } from "./consts";

const B4QuestionSeqNr = ({conversation}) => (
  <B4TextSup big className="font-semibold absolute top-0 left-0" color={B4Color.BLUE_DARK} outlined>{conversation.seq_nr}</B4TextSup>
)

interface Props {
  conversationWithStats: any,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  additionalFooterElements?: B4BubbleListFooterElementProps[]
}

export const B4QuestionBox = ({conversationWithStats = null, onClick = null, className = null, additionalFooterElements = null}: Props) => {
  const participants = conversationWithStats.stats.Participants
  const ideas = conversationWithStats.stats.Choices
  const battles = conversationWithStats.stats.PromptsDone

  return (
    <div className="relative w-full">
      <B4Bubble onClick={onClick} sharpCorner={B4Corner.NONE} className={clsx(className, 'space-y-b4-std-1/2')}>
        <B4Text html={conversationWithStats?.question} />
        <div className="md:flex md:space-x-b4-std">
          <B4SpaceHorizontal className="flex-wrap">
            <B4BubbleListFooterElement label={<MdPeopleOutline />} value={participants} />
            <B4BubbleListFooterElement label={<MdLightbulbOutline />} value={ideas} />
            <B4BubbleListFooterElement label={<MdOutlineVideogameAsset />} value={battles} />
          </B4SpaceHorizontal>
          {
            additionalFooterElements && additionalFooterElements.map((footerElement, i) => <B4BubbleListFooterElement key={i} label={footerElement.label} value={footerElement.value} />)
          }
        </div>
      </B4Bubble>
      <B4QuestionSeqNr conversation={conversationWithStats} />
    </div>
  )
}

export enum GlobalUserType {
  UNDEFINED,
  SUPER_ADMIN,
  ADMIN,
  SYSTEM_ADMIN,
  USER
}

export const useComputeGlobalUserType = () => {
  const { data: { me } = {} } = useMe()

  return () => {
    if (!me) {
      return GlobalUserType.UNDEFINED
    }

    if (me?.type === 'Admin') {
      return GlobalUserType.SYSTEM_ADMIN
    }

    if (me?.role === 'SuperAdmin') {
      return GlobalUserType.SUPER_ADMIN
    }

    if (me?.role === 'Admin') {
      return GlobalUserType.ADMIN
    }
    
    return GlobalUserType.USER
  }
}

export const useQuestionNavigate = () => {
  const roomNavigate = useRoomNavigate()

  return (e: React.MouseEvent<HTMLElement>, conversation, doExpiredAction: () => void) => {
     if (conversation.expired) {
      doExpiredAction()
    } else if (conversation.ended_or_expired) {
      roomNavigate(`/rate/question/${conversation.id}/stats`)
    } else if(e.metaKey) {
      roomNavigate(`/rate/question/${conversation.id}/vote`)
    } else {
      roomNavigate(`/rate/question/${conversation.id}/rate`)
    }
  }
}

interface B4BubbleQuestionListProps {
  conversationsWithStats: any,
  onClick?: (e: React.MouseEvent<HTMLElement>, element: any) => void,
  onCreate?: () => void,
  edit?: {
    onEdit: (element: any) => void,
    enabled?: (element: any) => boolean
  },
  showFooter?: boolean,
  showOpenTasks?: boolean
}

export const B4BubbleQuestionList = ({conversationsWithStats, onClick, edit, onCreate, showFooter = true, showOpenTasks = false}: B4BubbleQuestionListProps) => {
  const { t } = useTranslation()
  const computeGlobalUserType = useComputeGlobalUserType()
  const isAdmin = computeGlobalUserType() !== GlobalUserType.UNDEFINED && computeGlobalUserType() !== GlobalUserType.USER

  return (
    <B4BubbleList sharpCorner={isAdmin ? B4Corner.NONE :B4Corner.TOP_LEFT} data={orderBy(conversationsWithStats, ['ended', 'seq_nr'], ['asc', 'asc'])} 
      render={conversation => (<B4Text html={conversation.question} />)}
      edit={edit}
      onCreate={onCreate}
      renderFooter={!showFooter ? null : conversation => {
        const active = !conversation.ended
        const endsAt = conversation.end_at
        const participants = conversation.stats.Participants
        const ideas = conversation.stats.Choices
        const battles = conversation.stats.PromptsDone

        const calcEndsAtStr = () => {
          const dateEndsAt = endOfDay(new Date(endsAt))
          const diffDays = differenceInDays(dateEndsAt, endOfToday())
          if (diffDays >= 0 && diffDays <= 7) {
            return t('moreDays', {count: diffDays})
          }
      
          return `${t('txtEnds')} ${formatDate(dateEndsAt)}`
        }
        
        return (
          <div className={clsx("flex flex-wrap items-center gap-b4-std-1/2 pt-b4-std-1/2")}>
            <B4SpaceHorizontal>
              <B4BubbleListFooterElement label={<MdPeopleOutline />} value={participants} />
              <B4BubbleListFooterElement label={<MdLightbulbOutline />} value={ideas} />
              <B4BubbleListFooterElement label={<MdOutlineVideogameAsset />} value={battles} />
            </B4SpaceHorizontal>
            <div className="flex gap-b4-std-1/2 items-center self-end ml-auto">
              { endsAt && <B4TextTiny className="text-right" noWordBreak>{calcEndsAtStr()}</B4TextTiny> }
              { active !== null && <B4Chip disabled={!active}><B4TextTiny noWordBreak className="uppercase">{active ? t('txtActive') : t('txtClosed')}</B4TextTiny></B4Chip> }
            </div>
          </div>
        )
      }
    }
    onClick={onClick}
    renderRelative={conversation => (<>
      { isAdmin ? <B4QuestionSeqNr conversation={conversation} /> : null }
      { showOpenTasks ? <B4TextSup big className="absolute top-0 right-0" color={B4Color.ORANGE}>{conversation.open_tasks}</B4TextSup> : null }
    </>)}
  />
  )
}