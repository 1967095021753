import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_TIME_STATS_QUERY = graphql(/* GraphQL */ `
  query conversationTimeStats(
    $conversation_id: ID!
  ) {
    conversationTimeStats(id: $conversation_id) {
      max_answers
      max_votes
      max_skips
      max_battles
      events {
        label
        answers
        votes
        skips
        battles
      }
    }
  }
`)

const useConversationTimeStats = (options = {}) => useQuery(CONVERSATION_TIME_STATS_QUERY, options);

export default useConversationTimeStats;
