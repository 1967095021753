import { Controller, FieldError } from "react-hook-form"
import { B4ControlProps, B4InputClear, B4InputCommonProps, B4InputError, B4Label, genCommonInputStyling, useB4Validations } from "./commons"
import { useRef } from "react"
import clsx from "clsx"
import { isString } from "lodash"

interface B4ImageCommonProps extends B4InputCommonProps {
  accept?: string
}

interface B4ImageProps extends B4ImageCommonProps {
  value: string|Blob,
  error?: FieldError,
  onChange?: (value: string|Blob) => void,
}

const B4ImageInput = ({label = null, value, onChange = null, disabled = false, error, accept = 'image/*'}: B4ImageProps) => {
  const previewRef = useRef<HTMLImageElement | null>(null);
  const imageInputRef = useRef(null);

  if (value && !isString(value)) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        // @ts-ignore
        previewRef?.current && (previewRef.current.src = reader.result);
      },
      false,
    );
    reader.readAsDataURL(value);
  }

  return (
    <div>
      <B4Label label={label} required={false} />
      <div className={clsx(genCommonInputStyling(disabled, error),
        'flex items-center', {
          'cursor-pointer': !disabled
        }
      )} onClick={disabled ? null : () => imageInputRef.current.click()}>
        { value && !isString(value) && <img
          ref={previewRef}
          className='max-h-12'
          alt=''
        />}
        {/* if avatar comes from the backend, a url is delivered which is a string. If it comes from the input above, it is a Blob. */}
        { value && isString(value) && <img
            src={value}
            className='max-h-12'
          alt=''
          />
        }
        <div className="grow min-h-[1lh]" />
          { value && !disabled  && <B4InputClear onClick={() => {
            imageInputRef.current.value = null
            onChange(null)
          }} /> }
      </div>
      <input ref={imageInputRef} hidden accept={accept} type="file" onChange={value => onChange(value.target.files?.length ? value.target.files[0] : null)} />
      <B4InputError error={error} />
    </div>
  )
}

interface B4ControllerImageProps extends B4ControlProps, B4ImageCommonProps {}

export const B4ControllerImageInput = ({name, control, required = false, ...props}: B4ControllerImageProps) => {
  const { messages } = useB4Validations()
  return (
    <Controller<Record<string, string|Blob>>
      control={control}
      name={name}
      rules={{ required : required ? messages.required : null }}
      render={({ field: { onChange, value }, fieldState: {error} }) => (
        <B4ImageInput value={value} onChange={onChange} error={error} {...props} />
      )}
    />
  )
}