import clsx from "clsx"
import { useChangedEffect } from "../utils"
import { B4ControlProps, B4InputClear, B4InputCommonProps, B4InputError, B4InputExtension, B4InputIconButton, B4Label, genCommonInputStyling, useB4Validations } from "./commons"
import { HTMLInputTypeAttribute, useRef, useState } from "react"
import { B4TextSmall } from "../text"
import { Controller, FieldError } from "react-hook-form"
import { BiHide, BiShow } from "react-icons/bi"
import { isNumber } from "lodash"

interface B4TextInputCommonProps extends B4InputCommonProps {
  multiline?: boolean,
  type?: HTMLInputTypeAttribute,
  autoFocus?: boolean,
  spellcheck?: boolean,
  lang?: string,
  max?: number,
  onEnter?: () => void,
  rows?: number,
}


export interface B4TextInputProps<T> extends B4TextInputCommonProps {
  value?: T,
  onChange?: (value: T) => void,
  onBlur?: () => void,
  autoComplete?: string,
  error?: FieldError,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => void,
}

export const B4TextInput = ({value = null, multiline = false, label, placeholder = '', required = false, onChange = null, onBlur = null, onEnter = null, type = 'text', disabled = false, autoComplete = '', autoFocus = false, spellcheck = false, max = null, error, rows = null, onKeyDown = null, lang = null}: B4TextInputProps<string>) => {
  const inputRef = useRef(null)
  const [passwordShow, setPasswordShow] = useState(false)

  const onChangeInternal = (v: string) => {
    if (!onChange) return
    if ((type === 'date' || type === 'time') && !v) v = null
    onChange(v)
  }

  const onKeyDownInternal = (e: React.KeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    if (onEnter && e.key === 'Enter') onEnter()
    if (onKeyDown) onKeyDown(e)
  }

  useChangedEffect(() => {
    if (value && type === 'password') {
      const length = value.toString().length;
      // without set timeout the selection range is set before the input type is switched in the dom tree
      setTimeout(() => inputRef.current.setSelectionRange(length, length))
    }
  }, [passwordShow])

  return (
    <div>
      <B4Label label={label} required={required} />
      <div className={clsx(genCommonInputStyling(disabled, error),
        'flex', {
          'items-center': !multiline,
          'items-start': multiline,
        },
        'cursor-text'
      )} onClick={() => inputRef.current.focus()}>
        { multiline ? 
        <textarea
          className={clsx(
            'grow resize-none placeholder-gray-400 text-base',
            {
              'text-gray-600': disabled,
              'text-b4-primary': !disabled,
            }
          )}
          placeholder={placeholder} 
          onChange={e => onChangeInternal(e.target.value)}
          onBlur={onBlur}
          value={value || ''}
          ref={inputRef}
          disabled={disabled}
          autoFocus={autoFocus}
          spellCheck={spellcheck}
          maxLength={max || undefined}
          onKeyDown={onKeyDownInternal}
          rows={rows}
          lang={lang}
        /> :
        <input
          className={clsx(
            'grow placeholder-gray-400 bg-inherit text-base',
            {
              'text-gray-600': disabled,
              'text-b4-primary': !disabled,
              '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none': type === 'number', // disable arrow up/down for number input
            },
            'text-left', // otherwise, date is in the center on iphone
          )}
          type={(type === 'password' && passwordShow && 'text')  || type}
          placeholder={placeholder} 
          onChange={e => onChangeInternal(e.target.value)}
          onBlur={onBlur}
          value={value || ''}
          ref={inputRef}
          disabled={disabled}
          autoComplete={autoComplete}
          size={1}
          autoFocus={autoFocus}
          spellCheck={spellcheck}
          maxLength={max || undefined}
          onKeyDown={onKeyDownInternal}
          lang={lang}
        />}
        <div className="flex">
          {!disabled && !!value && <>
            {type === 'password' && <B4InputIconButton onClick={() => setPasswordShow(!passwordShow)}>{passwordShow ? <BiHide/> : <BiShow/>}</B4InputIconButton>} 
            <B4InputClear onClick={() => {
              setPasswordShow(false)
              onChangeInternal('')
            }} /> 
            </>}
        </div>
      </div>
      <B4InputError error={error} />
      { max && type !== 'number' && <B4InputExtension><B4TextSmall>{`${(value && String(value).length) || '0'}/${max}`}</B4TextSmall></B4InputExtension> }
    </div>
  )
}

export interface B4ControllerTextInputProps extends B4TextInputCommonProps, B4ControlProps {
  pattern?: {value: RegExp, message: string},
}

export const B4ControllerTextInput = ({name, control, required = false, disabled, pattern, type, ...props}: B4ControllerTextInputProps) => {
  const { messages, patterns } = useB4Validations()

  return (
    <Controller<Record<string, string>>
      control={control}
      disabled={disabled}
      name={name}
      rules={{ validate: required ? value => {
        if (!value || value.trim() === '') return messages.required
        return true
      } : null, pattern: pattern || (type === 'email' ? patterns.email : null) }}
      render={({ field: { onChange, onBlur, value, disabled }, fieldState: {error} }) => {
        
        return (
          <B4TextInput required={required} onChange={onChange} onBlur={onBlur} value={value} disabled={disabled} autoComplete={name} error={error} type={type} {...props} />
        )
      }}
    />
  )
}

interface B4ControllerNumberInputProps extends B4ControlProps, B4InputCommonProps {
  pattern?: {value: RegExp, message: string},
  autoFocus?: boolean,
  onEnter?: () => void,
}

export const B4ControllerNumberInput = ({name, control, required = false, disabled, ...props}: B4ControllerNumberInputProps) => {
  const { messages } = useB4Validations()

  return (
    <Controller<Record<string, number>>
      control={control}
      disabled={disabled}
      name={name}
      rules={{ required : required ? messages.required : null }}
      render={({ field: { onChange, onBlur, value, disabled }, fieldState: {error} }) => (
          <B4TextInput required={required} onChange={v => onChange(v ? parseInt(v) : null)} onBlur={onBlur} value={(isNumber(value) ? String(value) : null)} disabled={disabled} autoComplete={name} error={error} type="number" {...props} />
      )}
    />
  )
}