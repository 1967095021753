import { ApolloProvider } from '@apollo/client';

import {Suspense, useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';
import { genApolloClient } from './_infrastructure/graphql/graphqlClient';

import Routing from './_infrastructure/routes/routes';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { B4LoadingContext } from './utils/b4Context';
import fetchIntercept from 'fetch-intercept';

import './css/app.css';
import './_i18n/i18n';
import { saveInviteCode } from './utils';

import { B4SpaceVertical } from './design/atomic/layout';
import { B4Text, B4TextEmp, B4TextMedium } from './design/atomic/text';
import { B4Button } from './design/atomic/button';
import { BiSolidCarCrash } from 'react-icons/bi';

import { getWebInstrumentations, initializeFaro, ReactIntegration, withFaroErrorBoundary } from '@grafana/faro-react';
import TagManager from '@sooro-io/react-gtm-module';

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID
  });
}

if (process.env.REACT_APP_LOGS_COLLECTOR_URL) {
  initializeFaro({
    url: process.env.REACT_APP_LOGS_COLLECTOR_URL,
    apiKey: process.env.REACT_APP_LOGS_COLLECTOR_API_KEY,
    app: {
      name: process.env.REACT_APP_NAME || 'braine4_local',
      version: process.env.REACT_APP_VERSION || '0',
    },
    instrumentations: [
      // Load the default Web instrumentations
      ...getWebInstrumentations(),

      // minimum setup to get the FaroErrorBoundary support
      new ReactIntegration(),
    ],
  });
}

const ErrorComponent = ({error, resetError}) => {
  return (
    <div className='flex justify-center items-center w-full h-full p-b4-std'>
      <B4SpaceVertical className="text-center">
        <div className="flex justify-center"><BiSolidCarCrash className="text-9xl text-b4-primary" /></div>
        <B4TextMedium>We are very sorry</B4TextMedium>
        <B4TextEmp>Something went wrong.</B4TextEmp>
        <B4Text>Our engineers have been informed.</B4Text>
        <B4Text>We will fix the issue as soon as possible.</B4Text>
        <B4Button onClick={() => {
            resetError();
            window.location.pathname = '/';
          }}>Restart</B4Button>
      </B4SpaceVertical>
    </div>
  );
};

const App = withFaroErrorBoundary(Routing, {
  fallback: (error: Error, resetError: VoidFunction) => <ErrorComponent error={error} resetError={resetError} />
});

let callCounter = 0

const Root = () => {
  const [inviteCode] = useState(Object.fromEntries(new URLSearchParams(window.location.search).entries()).code)
  const [runningCalls, setRunningCalls] = useState(0)
  const [client, setClient] = useState(null)

  const counterUp = () => {
    callCounter = ++callCounter
    setRunningCalls(callCounter)
  }

  const counterDown = () => {
    callCounter = --callCounter
    if (callCounter < 0) {
      callCounter = 0
    }
    setRunningCalls(callCounter)
  }

  useEffect(() => {

    setClient(genApolloClient())

    // loading inc/dec for /api calls (which are done with native fetch calls)
    const backgroundExtension = '?isBackground'
    const unregisterFetchIntercept = fetchIntercept.register({
      request: (url, config) => {
        const isBackgroundCall = config && config.body?.includes ? config.body?.includes('unreadCount') : false
        if (!isBackgroundCall) {
          counterUp()
        }

        const newUrl = isBackgroundCall ?
          url + backgroundExtension :
          url
          
        return [newUrl, config];
      },

      requestError: function (error) {
        if (!error?.request?.url.includes(backgroundExtension)) {
          counterDown()
        }
        return Promise.reject(error);
      },

      response: function (response) {
        if (!response.url.includes(backgroundExtension)) {
          counterDown()
        }
        return response;
      },

      responseError: function (error) {
        if (!error?.request?.url.includes(backgroundExtension)) {
          counterDown()
        }
        return Promise.reject(error);
    }
    });

    saveInviteCode(inviteCode)

    return () => {
      unregisterFetchIntercept();
    };
  }, [inviteCode]);

  if (!client) {
    return null
  }

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={null}>
        <B4LoadingContext.Provider value={{
          loading: runningCalls > 0
        }}>
          <App />
        </B4LoadingContext.Provider>
      </Suspense>
    </ApolloProvider>
  );
};

const domNode = document.getElementById('app');
const root = createRoot(domNode);

root.render(<Root />);

defineCustomElements(window);
