/** @jsxImportSource @emotion/react */

import { B4ControlProps, B4InputCommonProps, B4Label, genCommonInputStyling, useB4Validations } from "./commons"
import { Controller, FieldError, useForm } from "react-hook-form"

import { $createHeadingNode, HeadingNode, HeadingTagType } from "@lexical/rich-text";

import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import { useEffect, useMemo, useState } from "react";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import { $getRoot, FORMAT_TEXT_COMMAND, REDO_COMMAND, TextFormatType, UNDO_COMMAND, ElementNode, TextNode, EditorConfig, DOMConversionMap, DOMConversionOutput, isHTMLElement, BaseSelection, $isRangeSelection, createCommand, $getSelection, RangeSelection, COMMAND_PRIORITY_NORMAL, CAN_UNDO_COMMAND, CAN_REDO_COMMAND, SerializedLexicalNode, SerializedElementNode, DecoratorNode, $getNodeByKey, LexicalEditor, $createRangeSelection, $setSelection, ElementFormatType, FORMAT_ELEMENT_COMMAND, $createParagraphNode, NodeKey } from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import { B4Button } from "../button";
import { MdFormatAlignCenter, MdFormatAlignJustify, MdFormatAlignLeft, MdFormatAlignRight, MdFormatBold, MdFormatItalic, MdFormatListBulleted, MdFormatUnderlined, MdOutlineImage, MdRedo, MdUndo } from "react-icons/md";
import { $generateNodesFromDOM } from '@lexical/html';
import { B4Modal} from "../modal";
import { B4ControllerNumberInput } from "./text";
import { B4Bubble } from "../bubble";
import { B4RulerHorizontal, B4RulerVertical, B4SpaceHorizontal, B4SpaceVertical } from "../layout";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { B4Color } from "../consts";
import { B4ControllerImageInput } from "./image";
import useUploadPublicImage from "../../../hooks/mutations/useUploadTenantImage";
import { isString } from "lodash";
import { B4_TEXT_HTML_CSS, B4TextSmall, B4TextTiny } from "../text";
import { css } from "@emotion/react";
import { $wrapNodes } from "@lexical/selection";
import { INSERT_UNORDERED_LIST_COMMAND, ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { mergeRegister } from "@lexical/utils";

const CustomHistoryActions = () => {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    return editor.registerCommand(
      CAN_UNDO_COMMAND,
      (payload) => {
        setCanUndo(payload);
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      CAN_REDO_COMMAND,
      (payload) => {
        setCanRedo(payload);
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  return (
    <>
      <B4Button disabled={!canUndo} color={B4Color.WHITE} onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)} full={false}><MdUndo/></B4Button>
      <B4Button disabled={!canRedo} color={B4Color.WHITE} onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)} full={false}><MdRedo/></B4Button>
    </>
  );
}

const CustomHeaderActions = () => {
  const [editor] = useLexicalComposerContext()

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createParagraphNode());
      }
    })
  }

  const formatHeader = (headingTag: HeadingTagType) => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode(headingTag));
      }
    })
  }

  const formatUnorderedList = () => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, null);
  };

  return (
    <>
      <B4Button color={B4Color.WHITE} onClick={() => formatParagraph()} full={false}><B4TextTiny className="font-bold">p</B4TextTiny></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => formatHeader('h1')} full={false}><B4TextTiny className="font-bold">h1</B4TextTiny></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => formatHeader('h2')} full={false}><B4TextTiny className="font-bold">h2</B4TextTiny></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => formatHeader('h3')} full={false}><B4TextTiny className="font-bold">h3</B4TextTiny></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => formatUnorderedList()} full={false}><MdFormatListBulleted/></B4Button>
    </>
  );
}


const CustomTextActions = () => {
  const [editor] = useLexicalComposerContext()

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const handleOnClick = (formatType: TextFormatType) => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType)
  }

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
          }
        });
      })
    );
  }, [editor]);

  return (
    <>
      <B4Button color={isBold ? B4Color.GREY : B4Color.WHITE} onClick={() => handleOnClick('bold')} full={false}><MdFormatBold/></B4Button>
      <B4Button color={isItalic ? B4Color.GREY : B4Color.WHITE} onClick={() => handleOnClick('italic')} full={false}><MdFormatItalic/></B4Button>
      <B4Button color={isUnderline ? B4Color.GREY : B4Color.WHITE} onClick={() => handleOnClick('underline')} full={false}><MdFormatUnderlined/></B4Button>
    </>
  );
}

const CustomElementActions = () => {
  const [editor] = useLexicalComposerContext()

  const handleOnClick = (formatType: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, formatType)
  }

  return (
    <>
      <B4Button color={B4Color.WHITE} onClick={() => handleOnClick('left')} full={false}><MdFormatAlignLeft/></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => handleOnClick('center')} full={false}><MdFormatAlignCenter/></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => handleOnClick('right')} full={false}><MdFormatAlignRight/></B4Button>
      <B4Button color={B4Color.WHITE} onClick={() => handleOnClick('justify')} full={false}><MdFormatAlignJustify/></B4Button>
    </>
  );
}

const B4RichTextImageAction = () => {
  const [editor] = useLexicalComposerContext();
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<BaseSelection>(null);

  const handleOnSave = (url, width, height) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {url, width, height, selection});
    setOpen(false)
  };

  return (
    <>
      <B4Button color={B4Color.WHITE} full={false} onClick={() => {
        setOpen(true)
        editor.update(() => {
          setSelection($getSelection()) // the current selection needs to be cached because it is losed because the onSave callback is asynchronous
        })
      }}><MdOutlineImage/></B4Button>
      <ImageModal open={open} onSave={handleOnSave} onCancel={() => setOpen(false)} />
    </>
  );
};

function $createB4RichTextImageNode(
  url: string,
  width: number | null = null,
  height: number | null = null
): B4RichTextImageNode {
  return new B4RichTextImageNode(url, width, height);
}

function $convertImgElement(domNode: Node): DOMConversionOutput {
  let node = null;
  if (isHTMLElement(domNode) && domNode.tagName === 'IMG') {
      node = $createB4RichTextImageNode(
        domNode.getAttribute('src') || '', 
        parseInt(domNode.getAttribute('width')) || null, 
        parseInt(domNode.getAttribute('height')) || null)
  }
  return { node };
}

interface SerializedB4RichTextImageNode extends SerializedElementNode<SerializedLexicalNode>
{
  src: string;
  width: number | null;
  height: number | null;
}

const B4RichTextImageComponent = ({ url, width, height, nodeKey, editor }) => {
  const [open, setOpen] = useState(false);
  const [storedSelectionInfo, setStoredSelectionInfo] = useState(null);

  const openModal = () => {
    // persist the current cursor position
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      if (selection) {
        const anchor = (selection as RangeSelection).anchor;
        const focus = (selection as RangeSelection).focus;
        setStoredSelectionInfo({
          anchorKey: anchor.key,
          anchorOffset: anchor.offset,
          focusKey: focus.key,
          focusOffset: focus.offset,
        });
      }
    });
    setOpen(true);
  };

  const closeModalAndFocus = () => {
    setOpen(false);
    setTimeout(() => {
      editor.focus();
      if (storedSelectionInfo) {
        // reapply the persisted cursor position
        editor.update(() => {
          const { anchorKey, anchorOffset, focusKey, focusOffset } = storedSelectionInfo;
          const anchorNode = $getNodeByKey(anchorKey);
          const focusNode = $getNodeByKey(focusKey);
          if (anchorNode && focusNode) {
            const rangeSelection = $createRangeSelection();
            rangeSelection.anchor.set(anchorKey, anchorOffset, 'text');
            rangeSelection.focus.set(focusKey, focusOffset, 'text');
            rangeSelection.dirty = true;
            $setSelection(rangeSelection);
          }
        });
      }
    }, 0);
  };

  const handleOnSave = (url, width, height) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node instanceof B4RichTextImageNode) {
        node.setURL(url);
        node.setWidth(width);
        node.setHeight(height);
      }
    });
    closeModalAndFocus();
  };

  const handleOnDelete = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node instanceof B4RichTextImageNode) {
        // Get the parent and sibling information before removing the node
        const parent = node.getParent();
        const previousSibling = node.getPreviousSibling();
        const nextSibling = node.getNextSibling();

        node.remove();

        // Create a new selection at an appropriate location
        const selection = $createRangeSelection();
        if (previousSibling) {
          // If there's a previous sibling, put selection at its end
          selection.anchor.set(previousSibling.getKey(), previousSibling.getTextContentSize(), 'text');
          selection.focus.set(previousSibling.getKey(), previousSibling.getTextContentSize(), 'text');
        } else if (nextSibling) {
          // If there's a next sibling, put selection at its start
          selection.anchor.set(nextSibling.getKey(), 0, 'text');
          selection.focus.set(nextSibling.getKey(), 0, 'text');
        } else if (parent) {
          // If no siblings, put selection at the end of the parent
          selection.anchor.set(parent.getKey(), 0, 'element');
          selection.focus.set(parent.getKey(), 0, 'element');
        }
        
        $setSelection(selection);
      }
    });
    closeModalAndFocus();
  };

  return (
    <>
      <img className="cursor-pointer inline-block" src={url} width={width} height={height} onClick={openModal} alt="" />
      <ImageModal 
        open={open} 
        url={url} 
        width={width}
        height={height}
        onSave={handleOnSave} 
        onCancel={closeModalAndFocus} 
        onDelete={handleOnDelete} 
      />
    </>
  );
};

class B4RichTextImageNode extends DecoratorNode<React.ReactNode> {
  url: string;
  width: number | null;
  height: number | null;

  static getType() : string {
    return 'image';
  }

  static clone(node: B4RichTextImageNode) : B4RichTextImageNode {
    return new B4RichTextImageNode(node.url, node.width, node.height, node.__key);
  }

  constructor(url: string, width: number | null = null, height: number | null = null, key?: NodeKey) {
    super(key);
    this.url = url;
    this.width = width;
    this.height = height;
  }

  createDOM(config: EditorConfig): HTMLElement {
    return document.createElement('span');
  }

  decorate(editor: LexicalEditor): React.ReactNode {
    return <B4RichTextImageComponent url={this.url} width={this.width} height={this.height} nodeKey={this.getKey()} editor={editor} />;
  }

  updateDOM(prevNode: B4RichTextImageNode, img: HTMLImageElement, config: EditorConfig): boolean {
    if (img instanceof HTMLImageElement) {
      img.src = this.url;
      img.width = this.width;
      img.height = this.height;
    }
    return false;
  }

  setURL(url: string) {
    const writable = this.getWritable();
    writable.url = url;
  }

  setWidth(width: number | null) {
    const writable = this.getWritable();
    writable.width = width;
  }

  setHeight(height: number | null) {
    const writable = this.getWritable();
    writable.height = height;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (domNode: HTMLElement) => ({
        conversion: $convertImgElement,
        priority: 0,
      }),
    };
  }


  exportJSON(): SerializedB4RichTextImageNode {
    return {
      src: this.url,
      width: this.width,
      height: this.height,
      type: 'image',
      version: 1,
      direction: 'ltr',
      children: [],
      format: 'left',
      indent: 0
    };
  }

  static importJSON(serializedNode: SerializedB4RichTextImageNode): B4RichTextImageNode {
    const { src, width, height } =
      serializedNode;
    const node = $createB4RichTextImageNode(src, width, height)
    return node;
  }

  canInsertTextBefore(): boolean {
    return true;
  }

  canInsertTextAfter(): boolean {
    return true;
  }

  canBeEmpty(): boolean {
    return false;
  }

  isInline(): true {
    return true;
  }
}

const INSERT_IMAGE_COMMAND = createCommand("create_image");
const ImagePlugin = () => {
  const [editor] = useLexicalComposerContext();

  if (!editor.hasNode(B4RichTextImageNode)) {
      throw new Error('ImagePlugin: "ImageNode" not registered on editor');
  }
  editor.registerCommand(
    INSERT_IMAGE_COMMAND,
      ({url, width, height, selection}: {url?: string, width?: number, height?: number, selection?: BaseSelection}) => {
        editor.update(() => {
          const _selection = selection;
          if ($isRangeSelection(_selection)) {
            if (url) {
              const imageNode = $createB4RichTextImageNode(url, width, height);
              _selection.insertNodes([imageNode]);
            } else {
              // If no payload is provided, you might want to show an image upload dialog
              // or prompt the user for an image URL
              console.log('No image URL provided');
            }
          }
        });
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
  );

  return null;
};

const textNodeToHtml = (node: TextNode): string => {
  let text = node.getTextContent()
  
  if (node.hasFormat('bold')) {
    text = `<strong>${text}</strong>`
  }
  
  if (node.hasFormat('italic')) {
    text = `<em>${text}</em>`
  }

  if (node.hasFormat('underline')) {
    text = `<u>${text}</u>`
  }

  return text
}

const lexicalNodeToTextAlignStyling = (node: ElementNode): string => {
  const formatType = node.getFormatType()

  return formatType && formatType !== 'start' ? ` style="text-align: ${formatType};"` : ''
}

const lexicalNodeToHtml = (node: ElementNode): string => {
  switch (node.getType()) {
    case 'root':
      const firstChild = node.getFirstChild();
      if (node.getChildren().length === 1 && firstChild.getType() === 'paragraph' && firstChild.getTextContent().trim() === '') {
        // content is empty
        return '';
      }
      return node.getChildren().map(lexicalNodeToHtml).join('')
    case 'paragraph':
      return `<p${lexicalNodeToTextAlignStyling(node)}>${node.getChildren().map(lexicalNodeToHtml).join('')}</p>`
    case 'text':
      if (node instanceof TextNode) {
        return textNodeToHtml(node as TextNode)
      }
      return '' // text without TextNode makes no sense
    case 'linebreak':
      return '<br/>'
    case 'image': {
      const imageNode = (node as unknown as B4RichTextImageNode)
      return `<img src="${imageNode.url}"${imageNode.width ? ` width="${imageNode.width}"` : ''}${imageNode.height ? ` height="${imageNode.height}"` : ''} style="display: inline-block;" />`
    }
    case 'heading': {
      const headingNode = (node as unknown as HeadingNode)
      headingNode.getFormatType()
      return `<${headingNode.getTag()}${lexicalNodeToTextAlignStyling(node)}>${node.getChildren().map(lexicalNodeToHtml).join('')}</${headingNode.getTag()}>`
    }
    case 'list': {
      const listNode = (node as unknown as ListNode)
      return `<${listNode.getTag()}>${node.getChildren().map(lexicalNodeToHtml).join('')}</${listNode.getTag()}>`
    }
    case 'listitem': {
      return `<li${lexicalNodeToTextAlignStyling(node)}>${node.getChildren().map(lexicalNodeToHtml).join('')}</li>`
    }
    default:
      console.log(node.getType())
      console.log(node)
      return ''
  }
}

const OnChangePlugin = ({onChange}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
        listener.editorState.read(() => {
          const root = $getRoot();
          onChange(lexicalNodeToHtml(root))

          // unomment for debugging
          // console.log(listener.editorState.toJSON());

        });
    });
  }, [editor]);

  return null;
}


const parseHtml = (editor, html) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  return $generateNodesFromDOM(editor, dom);
}

const initializeEditor = (editor, initialHtml) => {
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    const nodes = parseHtml(editor, '<div>' + initialHtml + '</div>'); // default wrap into <div>...</div> is done because the node append does not allow having root texts or line breaks. Luckily, the div is somewhere filtered out and not returned in the OnChangePlugin.
    nodes.forEach(node => root.append(node));
  });
}

const InitTextPlugin = ({value, cached}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    // Now you have access to the editor instance
    if (editor && value && value !== cached) {
      initializeEditor(editor, value);
    }
  }, [editor, value]);

  return null;
}

const ImageModal = ({open, onSave, onCancel, onDelete = null, url = null, width = null, height = null}) => {
  const { t } = useTranslation()
  const [uploadPublicImage, {loading}] = useUploadPublicImage()

  const onSubmit = async data => {
    let newUrl = url
    if (data.image && !isString(data.image)) {
      // image is file -> upload it
      const {data: {uploadPublicImage: receivedUrl} = {}} = await uploadPublicImage({
        variables: { 
          file: data.image,
        }
      })
      newUrl = receivedUrl
    }

    onSave(newUrl, data.width, data.height)
  }

  const { handleSubmit, control, reset, formState: {isDirty} } = useForm({
    defaultValues: {
      image: url,
      width,
      height
    }
  });

  useEffect(() => {
    reset({image: url, width, height})
  }, [url, width, height, open])

  return (
    <B4Modal open={open} onClose={onCancel}>
        <B4Bubble 
          className="text-left" // because modals are currently injected inlined, we need to revert the text alignment potentially set by lexical
          color={B4Color.GREEN_NEON}>
          <B4SpaceVertical>
            <B4ControllerImageInput name="image" label={t('lblImage')} placeholder={t('lblImage')} control={control} accept="image/*" />
            <B4ControllerNumberInput name="width" label={t('lblWidth')} placeholder={t('lblWidth')} control={control} />
            <B4ControllerNumberInput name="height" label={t('lblHeight')} placeholder={t('lblHeight')} control={control} />
            <B4TextSmall html={t('lblDimensionDescription')} />
            <B4SpaceHorizontal>
              { onDelete && <B4Button color={B4Color.RED} onClick={() => onDelete()}>{t('btnDelete')}</B4Button> }
              <B4Button color={B4Color.GREY} disabled={loading} onClick={onCancel}>{t('txtCancel')}</B4Button>
              <B4Button color={B4Color.BLUE} disabled={!isDirty || loading} onClick={handleSubmit(onSubmit)}>{t('txtSave')}</B4Button>
            </B4SpaceHorizontal>
          </B4SpaceVertical>
        </B4Bubble>
      </B4Modal>
  );
};

interface B4RichTextInputCommonProps extends B4InputCommonProps {
  autofocus?: boolean,
}

interface B4RichTextInputProps extends B4RichTextInputCommonProps {
  value?: string,
  onChange: (value: string) => void,
  placeholder?: string,
  required?: boolean,
  onBlur?: () => void,
  disabled?: boolean,
  error?: FieldError,
}


export const B4RichTextInput = ({value = null, label, onChange, placeholder = '', required = false, onBlur = null, disabled, error, autofocus = false}: B4RichTextInputProps) => {
  const [cached, setCached] = useState(null);
  const CustomContent = useMemo(() => {
    return (
      <ContentEditable className="w-full" />
    )
  }, []);

  const CustomPlaceholder = useMemo(() => {
    return (
      <span className="text-gray-400">{placeholder}</span>
    )
  }, [placeholder]);

  const lexicalConfig: InitialConfigType = {
    namespace: 'My Rich Text Editor',
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      B4RichTextImageNode,
    ],
    theme: {
      text: {
        underline: "editor-text-underline",
        bold: "editor-text-bold",
        italic: "editor-text-italic",
      }
    },
    onError: (e) => {
        console.log('ERROR:', e)
    },
    editorState: autofocus ? undefined : null,
  }

  return (<div>
    <B4Label label={label} required={required} />
    <div className={clsx(genCommonInputStyling(disabled, error))} onBlur={onBlur} css={[B4_TEXT_HTML_CSS, css`
      .editor-text-underline {
        text-decoration: underline;
      }
      .editor-text-bold {
        font-weight: bold;
      }
      .editor-text-italic {
        font-style: italic;
      }
    `]}>
      <LexicalComposer initialConfig={lexicalConfig}>
        <div className="flex gap-b4-std-1/4 flex-wrap">
          <CustomHeaderActions />
          <B4RulerVertical />
          <CustomTextActions />
          <B4RulerVertical />
          <CustomElementActions />
          <B4RulerVertical />
          <B4RichTextImageAction />
          <B4RulerVertical />
          <CustomHistoryActions />
        </div>
        <B4SpaceVertical>
          <B4RulerHorizontal className="mt-b4-std-1/4" />
          <div className={clsx({
            'text-gray-600': disabled,
            'text-b4-primary': !disabled,
          })}>
            <RichTextPlugin
              contentEditable={CustomContent}
              placeholder={CustomPlaceholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
          </div>
        </B4SpaceVertical>
        <HistoryPlugin />
        <OnChangePlugin  onChange={value => {
          setCached(value)
          onChange(value)
        }}  />
        <ImagePlugin />
        <InitTextPlugin value={value} cached={cached} />
        <ListPlugin />
      </LexicalComposer>
    </div>
  </div>)
}

interface B4ControllerRichTextInputProps extends B4RichTextInputCommonProps, B4ControlProps {}

export const B4ControllerRichTextInput = ({name, control, required = false, disabled, ...props}: B4ControllerRichTextInputProps) => {
  const { messages } = useB4Validations()

  return (
    <Controller<Record<string,string>>
      control={control}
      name={name}
      rules={{required: required ? messages.required : null}}
      render={({ field: { onChange, onBlur, value, disabled }, fieldState: {error} }) => {
        return (
          <B4RichTextInput required={required} value={value} onChange={value => {
            onChange(value)
          }} onBlur={onBlur} disabled={disabled} error={error} {...props} />
        )
      }}
    />
  )
}