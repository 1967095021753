import {useQuery} from '@apollo/client';
import { graphql } from '../../gql';

export const CONVERSATIONS_WITH_STATS_QUERY = graphql(/* GraphQL */ `
  query conversationsWithStats(
    $tenant_id: ID
  ) {
    conversationsWithStats(
      tenant_id: $tenant_id
    ) {
      id
      tenant_id
      question
      expired
      created_at
      deleted_at
      start_at
      end_at
      ended
      ended_or_expired
      open_tasks
      seq_nr
      user {
        id
        avatar
        name
        country
        town {
          id
          code
          name
        }
        age
      }
      stats {
        Participants
        Choices
        PromptsDone
        Votes
        Skips
      }
    }
  }
`);

const useConversationsWithStats = (options = {}) => useQuery(CONVERSATIONS_WITH_STATS_QUERY, options);

export default useConversationsWithStats;
