import { animated, useSpring } from '@react-spring/web'
import clsx from "clsx";
import { useEffect } from "react";
import { B4Color } from './consts';

interface B4ButtonProps {
  children: React.ReactNode,
  color?: B4Color,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  disabled?: boolean,
  timeout?: boolean,
  full?: boolean,
  className?: string,
  icon?: React.ReactNode
}
export const B4Button = ({children, onClick = null, color = B4Color.GREEN, disabled = false, timeout = false, full = true, className = null, icon = null}: B4ButtonProps) => {
  const [styles, api] = useSpring(() => ({
    from: { width: '100%' },

  }), [])

  useEffect(() => {
    if (timeout) {
      api.start({
        from: {width: '100%'},
        to: { width: '0%' },
        config: { duration: 7500 }
      })

      const timeout = setTimeout(() => {
        onClick && onClick(null)
      }, 7500)

      return () => clearTimeout(timeout)
    }
  }, [timeout]);

  return (
    <button
      disabled={disabled}
      className={clsx('rounded font-medium text-base relative active:opacity-50', className, {
        'w-full': full,
        'opacity-50': disabled,
        'bg-b4-secondary text-b4-primary': color === B4Color.GREEN,
        'bg-b4-question text-white': color === B4Color.BLUE,
        'bg-b4-primary text-white': color === B4Color.BLUE_DARK,
        'bg-b4-red text-white': color === B4Color.RED,
        'bg-white text-b4-primary': color === B4Color.WHITE,
        'bg-gray-300 text-b4-primary': color === B4Color.GREY,
      })}
      onClick={onClick}>
        <div className="p-2 flex items-center justify-center gap-b4-std-1/2">
          <div>{icon}</div>
          <div className="text-nowrap">{ children }</div>
        </div>
        { timeout && <div className="absolute bottom-0 flex justify-end w-full"><animated.div style={styles} className="h-0.5 bg-b4-primary" /></div>}
    </button>
  )
}

export const B4IconButtonNoP = ({children, className = null, onClick = null, disabled = false, color = null, big = false, tabIndex = null, noBackground = false}) => (
  <button className={clsx('rounded-full flex justify-center items-center active:opacity-50', {
    'bg-white shadow-md shadow-b4-bg-dark text-b4-primary': color === B4Color.WHITE && !noBackground,
    'text-white border-white': color === B4Color.WHITE && noBackground,
    'bg-b4-primary text-white shadow-md shadow-white': color === B4Color.BLUE_DARK,
    'bg-b4-title text-white': color === B4Color.BLUE,
    'bg-b4-secondary text-b4-primary': color === B4Color.GREEN,
    'bg-b4-red text-white': color === B4Color.RED,
    'opacity-50': disabled,
    'text-2xl': big,
    'text-xl': !big,
  } , className)} onClick={onClick} disabled={disabled} tabIndex={tabIndex}>{children}</button>
)

export const B4IconButton = ({className = null, children, color = null, ...rest}) => (
  <B4IconButtonNoP className={clsx('p-2', {
    '-m-2': color === null
  }, className)} color={color} {...rest}>{children}</B4IconButtonNoP>
)

export const B4IconButtonWithText = ({icon, text, color = B4Color.GREEN, onClick = null, disabled = false}) => (
  <button disabled={disabled} className={clsx('p-2 active:opacity-50 cursor-pointer', {
    'text-gray-400': color === B4Color.GREY,
    'text-b4-secondary': color === B4Color.GREEN,
    'text-b4-primary': color === B4Color.BLUE_DARK,
  })} onClick={onClick}>
    <div className="text-4xl flex justify-center">{icon}</div>
    <div className="text-sm flex justify-center">{text}</div>
  </button>
)

export enum B4ButtonOutlinedBorder {
  ALL,
  BOTTOM,
  NONE,
}

export const B4ButtonOutlined = ({children, full = true, onClick = null, className = null, color = B4Color.WHITE, square = false,
                                  sharp = false, icon = null, filled = false, border = B4ButtonOutlinedBorder.ALL, disabled = false, small = false}) => (
  <button disabled={disabled} className={clsx('font-white active:opacity-50 py-1', {
    'border': border === B4ButtonOutlinedBorder.ALL,
    'border-b': border === B4ButtonOutlinedBorder.BOTTOM,
    'rounded-full': !sharp && border === B4ButtonOutlinedBorder.ALL,
    'rounded': sharp && border === B4ButtonOutlinedBorder.ALL,
    'w-full': full,
    'px-2': !square,
    'px-1': square,
    'border-white': color === B4Color.WHITE,
    'bg-white': color === B4Color.WHITE && filled,
    'text-white': (color === B4Color.WHITE && !filled) || (filled && ![B4Color.WHITE, B4Color.GREEN].includes(color)),
    'border-b4-primary': color === B4Color.BLUE_DARK,
    'bg-b4-primary': color === B4Color.BLUE_DARK && filled,
    'text-b4-primary': (color === B4Color.BLUE_DARK && !filled) || ([B4Color.WHITE, B4Color.GREEN].includes(color) && filled),
    'border-b4-title': color === B4Color.BLUE,
    'bg-b4-title': color === B4Color.BLUE && filled,
    'text-b4-title': (color === B4Color.BLUE && !filled),
    'border-b4-secondary': color === B4Color.GREEN,
    'bg-b4-secondary': color === B4Color.GREEN && filled,
    'text-b4-secondary': color === B4Color.GREEN && !filled,
    'border-b4-orange': color === B4Color.ORANGE,
    'bg-b4-orange': color === B4Color.ORANGE && filled,
    'text-b4-orange': color === B4Color.ORANGE && !filled,
  }, className)} onClick={onClick}><div className={clsx('flex items-center space-x-1 justify-center', {
    'text-normal': !small,
    'text-xs': small,
  })}><div>{children}</div>{icon}</div></button>
)