import clsx from "clsx";
import { B4NormalDistVerticalEdgesIncluded, B4RulerHorizontal, B4RulerVertical, B4SpaceVertical } from "./layout";
import { B4Text, B4TextSmall, B4TextTiny } from "./text";
import { B4Color } from "./consts";
import { Fragment } from "react";
import { B4BackgroundType, formatNumber, useB4BackgroundContextContext } from "./utils";
import { B4ToolTip } from "./modal";
import { B4Chip } from "./chip";
import { range } from "lodash";

export const B4TimelineChart = ({values, color=B4Color.GREEN_NEON}: {values: number[], color?: B4Color}) => {
  return (
    <div className="relative h-2.5">
      {
        values.map((value, i) => (
          <div key={i} className={clsx('absolute top-0 bottom-0 w-2.5 -translate-x-1/2')}  style={{left: `${value*100}%`}}>
            <div className={clsx('opacity-70', {
              'bg-b4-secondary rounded-full h-full': color === B4Color.GREEN_NEON,
              'w-0 h-0 border-l-[0.3125rem] border-r-[0.3125rem] border-b-[0.625rem] border-l-transparent border-r-transparent border-b-b4-orange': color === B4Color.ORANGE,
            })} />
          </div>
        ))
      }
    </div>
  )
}

const compLabelsShown = (maxLength: number, lengthLabels: number): number[] => {
  const step = Math.ceil(lengthLabels / maxLength)
  return range(0, lengthLabels, step)
}


export const B4BarChartMultiple = (
  {bars, config, barBgColor: bgColor = null, labelsVertical = null, title}: 
  {title?: string, barBgColor?: B4Color, bars: {values: number[], label: string}[], config: {title: string, color: B4Color, maxValue?: number}[], labelsVertical?: boolean}) => {

  const { bgType } = useB4BackgroundContextContext()
  const isDark = bgType === B4BackgroundType.DARK


  if (bars.some(b =>b.values.length !== config.length)) {
    console.error('B4BarChartMultiple: all bars must have the number of values configured')
    return null;
  }
  
  const labels = bars.map(b => b.label)
  const internalMaxValue = Math.max(...bars.map((bar, i) => {
    return Math.max(...bar.values.map((value, j) => {
      if (config[j].maxValue) {
        return config[j].maxValue
      }
      return value
    }))
  }))
  const sumPerValue = bars.reduce((acc, bar) => bar.values.map((value, j) => (acc[j] || 0) + value), [])
  
  const internalLabelsVertical = labelsVertical === null ? bars.length > 5 : labelsVertical
  const showPercentage = bars.length <= 4
  const labelsShown = compLabelsShown(12, bars.length)
  const maxValueHalf = Math.round(internalMaxValue / 2)
  const getPercentage = (bar: number, value: number) => Math.round(value / sumPerValue[bar] * 100)

  return (<B4SpaceVertical className="print:break-inside-avoid">
    <div className="flex justify-between items-start">
      {title ? <B4Text color={isDark ? B4Color.WHITE : B4Color.BLUE_DARK}>{title}</B4Text> : <div/> }
      <div className="flex gap-b4-std-1/2">
        { 
            config.map(({title, color}, i) => title ? <B4Chip filled key={i} color={color}><B4TextSmall noWordBreak color={color === B4Color.GREEN_NEON ? B4Color.BLUE_DARK: B4Color.WHITE}>{title}</B4TextSmall></B4Chip> : <div key={i}/>)
        }
      </div>
    </div>
    <div className="grid grid-cols-[auto_1fr] gap-b4-std-1/2">
      <div className="flex gap-b4-std-1/2">
        <B4NormalDistVerticalEdgesIncluded elements={[
          <B4TextTiny color={B4Color.WHITE}>{formatNumber(internalMaxValue)}</B4TextTiny>,
          maxValueHalf !== internalMaxValue ? <B4TextTiny color={isDark ? B4Color.WHITE : B4Color.BLUE_DARK}>{formatNumber(internalMaxValue / 2)}</B4TextTiny> : null,
          <div/>
        ]} />
        <B4RulerVertical className="h-full"/>
      </div>
      <div className={clsx('flex h-44', {
        'gap-b4-std-1/2': bars.length < 32,
        'gap-b4-std-1/4': bars.length >= 32 && bars.length <= 64,
        'gap-b4-std-1/8': bars.length >= 64 && bars.length <= 128
      })}>
        {
          bars.map((bar, i) => (
            <B4ToolTip key={i} content={<div>
              <B4TextTiny className="font-bold" html={bar.label} />
              {
                bar.values.map((value, j) => (<Fragment key={j}>
                <B4TextTiny html={`${config[j].title ? config[j].title + ' ' : ''} ${formatNumber(value)} (${getPercentage(j, value)}%)`} />
                </Fragment>))
              }
            </div>}>
              <div className="h-full grow flex">
                {
                  bar.values.map((value, j) => (
                      <div key={j} className={clsx('h-full grow relative', {
                        'bg-gray-300': bgColor === B4Color.GREY,
                      })}>
                        <div className={clsx('absolute left-0 right-0 bottom-0 transition-all', {
                          'bg-b4-title': config[j].color === B4Color.BLUE,
                          'bg-b4-secondary': config[j].color === B4Color.GREEN_NEON,
                        })} style={{height: `${Math.round(value*100 / internalMaxValue)}%`}} />
                        { showPercentage && <B4TextSmall className={clsx('absolute left-0 right-0 bottom-b4-std-1/4 flex justify-center', { 'text-white': config[j].color === B4Color.BLUE})} html={`${getPercentage(j, value)}%`} /> }
                      </div>
                    )
                  )
                }
              </div>
            </B4ToolTip>
          ))
        }
      </div>
      <div/>
      <div className="space-y-b4-std-1/2 min-w-0">
        <B4RulerHorizontal />
        <div className="flex">{labels.map((label, i) => (<div key={i} className="grow basis-0 min-w-0 flex justify-center text-center">{labelsShown.includes(i) && <B4TextTiny noWordBreak style={internalLabelsVertical ? {
          writingMode: 'vertical-lr',
          transform: 'rotate(180deg)'
        }: null} color={isDark ? B4Color.WHITE : B4Color.BLUE_DARK} key={i} html={label} />}</div>))}</div>
      </div>
    </div>
  </B4SpaceVertical>)
}

export const B4BarChart = (
  {bars, color, bgColor = null, maxValue = null, labelsVertical = null, title}: 
  {title: string, color: B4Color, bgColor?: B4Color, bars: {value: number, label: string}[], maxValue?: number, labelsVertical?: boolean}) => (
    <B4BarChartMultiple title={title} bars={bars.map(({value, label}) => ({values: [value], label}))} config={[{title: '', color, maxValue}]} barBgColor={bgColor} labelsVertical={labelsVertical} />
  )