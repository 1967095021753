import { useTranslation } from "react-i18next";
import { ComponentType, Fragment, PropsWithChildren} from "react";
import { B4Color } from "../../design/atomic/consts";
import { useParams } from "react-router-dom";
import { B4BarChart, B4BarChartMultiple } from "../../design/atomic/chart";
import { B4Grid, B4GridColumnType, B4List, B4ListLabelValue } from "../../design/atomic/table";
import { useQuestionStats } from "../../hooks";
import { MdArrowForward, MdCallSplit, MdContentCopy, MdDelete, MdLightbulbOutline, MdOutlineVideogameAsset, MdPeopleOutline } from "react-icons/md";
import { isNumber } from "lodash";
import { formatDate } from "../../design/atomic/utils";
import useConversationParticipantsStats from "../../hooks/queries/useConversationParticipantsStats";
import useConversationTimeStats from "../../hooks/queries/useConversationTimeStats";
import useConversationTimeline from "../../hooks/queries/useConversationTimeline";
import { B4Text, B4TextEmp, B4TextSmallArrowed } from "../../design/atomic/text";
import { B4RulerHorizontal } from "../../design/atomic/layout";
import { AnswerStatisticalEvaluation, ConversationAdminTasksReport, ConversationQuickEvaluation, ConversationStatisticalEvaluation } from "../../gql/graphql";
import { CredibleIntervalGraph, IdeasGraphQuick, PolarityGraph, PriorityRankingGraph } from "./conversation-reporting-graphs";

interface AdminTasksTableData { 
  text: string,
  subs?: { text: string }[]
}

const AdminTasksTable = ({ data, icon }: { data: AdminTasksTableData[], icon: React.ReactNode }) => {
  const { t } = useTranslation()
  return (
    <B4Grid rulersHorizontal={false} columns={[B4GridColumnType.STRETCH]} cells={[
      <B4TextEmp className="flex items-center gap-b4-std-1/2">{icon} {t('lblIdea')}</B4TextEmp>,
      <B4RulerHorizontal className="col-span-full" color={B4Color.BLUE_DARK} />,
      ...data.flatMap(item => [
        <B4Text>{item.text}</B4Text>,
        (item.subs ? item.subs?.map((subItem, i) => <B4TextSmallArrowed key={i}>{subItem.text}</B4TextSmallArrowed>) : null),
        <B4RulerHorizontal className="col-span-full" color={B4Color.BLUE_DARK} />
      ])
    ]} />
  )
}

interface ConversationAdminTasksProps {
  tPre?: string,
  wrapper?: ComponentType<PropsWithChildren<any>>,
  data: ConversationAdminTasksReport
}

interface ConversationAdminTasksCommonProps extends ConversationAdminTasksProps {
  icon: React.ReactNode, 
  genData: (conversationAdminTasksReport: ConversationAdminTasksReport) => AdminTasksTableData[],
}

const ConversationAdminTasksCommon = ({icon, genData, tPre, wrapper: Wrapper = Fragment, data}: ConversationAdminTasksCommonProps) => {
  const listData = data ? genData(data) : []

  if (!listData.length) return
  
  return (<>
    {tPre && <B4Text t={tPre} />}
    <Wrapper>
      <AdminTasksTable
        icon={icon}
        data={listData}
      />
    </Wrapper>
  </>)
}

export const ConversationDuplicates = (props: ConversationAdminTasksProps) => (
  <ConversationAdminTasksCommon
    icon={<MdContentCopy />}
    genData={conversationAdminTasksReport => conversationAdminTasksReport?.answers_with_duplicates.map(answer => ({ text: answer.answer.answer, subs: [{ text: answer.duplicate_of.answer, nr: answer }] }))}
    {...props} />
)

export const ConversationSplits = (props: ConversationAdminTasksProps) => (
  <ConversationAdminTasksCommon
    icon={<MdCallSplit />}
    genData={conversationAdminTasksReport => conversationAdminTasksReport?.split_answers.map(answer => ({ text: answer.answer.answer, subs: answer.split_to.map(split => ({ text: split.answer })) }))}
    {...props} />
)

export const ConversationMoves = (props: ConversationAdminTasksProps) => (
  <ConversationAdminTasksCommon
    icon={<MdArrowForward />}
    genData={conversationAdminTasksReport => conversationAdminTasksReport?.moved_answers.map(answer => ({ text: answer.answer.answer, subs: [{ text: answer.target_conversation }] }))}
    {...props} />
)

export const ConversationDeletes = (props: ConversationAdminTasksProps) => (
  <ConversationAdminTasksCommon
    icon={<MdDelete />}
    genData={conversationAdminTasksReport => conversationAdminTasksReport?.deleted_answers.map(answer => ({ text: answer.answer }))}
    {...props} />
)

export const ConversationParticipantsStats = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { data: { conversationParticipantsStats } = {}, loading } = useConversationParticipantsStats({
    fetchPolicy: 'cache-and-network',
    variables: { conversation_id: conversationId }
  })
  return loading ? null : conversationParticipantsStats.map((participantStats, i) => (
    participantStats.categories && <Fragment key={i}>
      <B4BarChart labelsVertical={false} bgColor={B4Color.GREY} title={participantStats.name} bars={participantStats.categories.map(stat => ({ value: stat.count, label: stat.label }))} maxValue={participantStats.total} color={B4Color.GREEN_NEON} />
    </Fragment>))
}

export const ConversationTimeStats = () => {
  const { t } = useTranslation()
  const { conversationId } = useParams<{ conversationId: string }>();
  const { data: { conversationTimeStats } = {}, loading } = useConversationTimeStats({
    fetchPolicy: 'cache-and-network',
    variables: { conversation_id: conversationId }
  })
  return loading ? null : <>
    <B4BarChartMultiple title={t('lblTimeTimeline')} bars={conversationTimeStats.events.map(event => {
      return {values: [event.votes], label: event.label}
    })} config={[{
      title: t('lblVotes'),
      color: B4Color.BLUE
    }]}/>
    <B4BarChartMultiple title={t('lblTimeTimeline')} bars={conversationTimeStats.events.map(event => {
      return {values: [event.answers], label: event.label}
    })} config={[{
      title: t('lblIdeas'),
      color: B4Color.GREEN_NEON
    }]}/>
  </>
}

export const ConversationTimeline = () => {
  const { t } = useTranslation()
  const { conversationId } = useParams<{ conversationId: string }>();
  const { data: { conversationTimeline } = {}, loading } = useConversationTimeline({
    fetchPolicy: 'cache-and-network',
    variables: { conversation_id: conversationId }
  })
  return loading ? null : <>
    <B4BarChartMultiple title={t('lblDaysTimeline')} bars={conversationTimeline.events.map(event => {
      return {values: [event.votes], label: formatDate(event.label)}
    })} config={[{
      title: t('lblVotes'),
      color: B4Color.BLUE
    }]}/>
    
    <B4BarChartMultiple title={t('lblDaysTimeline')} bars={conversationTimeline.events.map(event => {
      return {values: [event.answers], label: formatDate(event.label)}
    })} config={[{
      title: t('lblIdeas'),
      color: B4Color.GREEN_NEON
    }]}/>
  </>
}

export const ConversationStats = ({ wrapper: Wrapper = Fragment }: { wrapper?: ComponentType<PropsWithChildren<any>> }) => {
  const { t } = useTranslation()
  const { conversationId } = useParams<{ conversationId: string }>();
  const { data: { GetQuestionStats } = {}, loading } = useQuestionStats({
    variables: { conversation_id: conversationId },
    fetchPolicy: 'no-cache',
  })

  const genInclPercentage = (label: string, labelPercentage: string, value?: number, total?: number): B4ListLabelValue[] => {
    const numbersPassed = isNumber(value) && isNumber(total)
    return ([
      {
        label,
        value
      },
      {
        label: labelPercentage,
        value: numbersPassed ? value / total : null,
        percentage: numbersPassed,
        indented: true
      }
    ])
  }

  const statsParticipants: B4ListLabelValue[] = [
    {
      label: t('lblTotalContributors'),
      value: GetQuestionStats?.total_participants,
      emp: true,
      icon: <MdPeopleOutline />
    },
    ...genInclPercentage(t('lblStatsUsersHavingNewIdeas'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.choices_contributors, GetQuestionStats?.total_participants),
    ...genInclPercentage(t('lblStatsUsersHavingBattles'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.prompts_participants, GetQuestionStats?.total_participants),
    ...genInclPercentage(t('lblStatsUsersHavingVotes'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.voters, GetQuestionStats?.total_participants),
    ...genInclPercentage(t('lblStatsUsersHavingEquals'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.both_good_skippers, GetQuestionStats?.total_participants),
    ...genInclPercentage(t('lblStatsUsersHavingSkips'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.regular_skippers, GetQuestionStats?.total_participants),
    ...genInclPercentage(t('lblStatsBlockedUsers'), t('lblStatsInPercentageOfAllUsers'), GetQuestionStats?.excluded_users, GetQuestionStats?.total_participants),
  ]

  const statsIdeas: B4ListLabelValue[] = [
    {
      label: t('lblTotalIdeas'),
      value: GetQuestionStats?.choices,
      emp: true,
      icon: <MdLightbulbOutline />
    },
    {
      label: t('lblStatsThereofInitialIdeas'),
      value: GetQuestionStats?.choices_seed,
    },
    ...genInclPercentage(t('lblStatsForNewIdeas'), t('lblStatsInPercentageOfAllIdeas'), GetQuestionStats?.choices_new, GetQuestionStats?.choices),
    ...genInclPercentage(t('lblStatsThereofDuplicateIdeas'), t('lblStatsInPercentageOfAllIdeas'), GetQuestionStats?.choices_duplicates, GetQuestionStats?.choices),

  ]

  const statsBattles: B4ListLabelValue[] = [
    {
      label: t('lblTotalBattles'),
      value: GetQuestionStats?.prompts_done,
      emp: true,
      icon: <MdOutlineVideogameAsset />
    },
    ...genInclPercentage(t('lblStatsPartOfVotes'), t('lblStatsInPercentageOfAllBattles'), GetQuestionStats?.votes, GetQuestionStats?.prompts_done),
    ...genInclPercentage(t('lblStatsThereofBothGoodSkips'), t('lblStatsInPercentageOfAllBattles'), GetQuestionStats?.both_good_skips, GetQuestionStats?.prompts_done),
    ...genInclPercentage(t('lblStatsPartOfSkips'), t('lblStatsInPercentageOfAllBattles'), GetQuestionStats?.regular_skips, GetQuestionStats?.prompts_done),
  ]

  const statsAverage: B4ListLabelValue[] = [
    {
      label: t('lblStatsAvergeContributes'),
      value: null,
      emp: true
    },
    {
      label: t('lblStatsNoBattles'),
      value: GetQuestionStats?.prompts_done_avg,
    },
    {
      label: t('lblStatsForNewIdeasPerParticipant'),
      value: GetQuestionStats?.choices_avg,
    },
    {
      label: t('lblStatsVotes'),
      value: GetQuestionStats?.votes_avg,
    },
    {
      label: t('lblStatsBothGoodSkips'),
      value: GetQuestionStats?.both_good_skips_avg,
    },
    {
      label: t('lblStatsSkips'),
      value: GetQuestionStats?.regular_skips_avg,
    },
  ]

  return loading ? null : <>
    <Wrapper><B4List centralised={false} items={statsParticipants}/></Wrapper>
    <Wrapper><B4List centralised={false} items={statsIdeas}/></Wrapper>
    <Wrapper><B4List centralised={false} items={statsBattles}/></Wrapper>
    <Wrapper><B4List centralised={false} items={statsAverage}/></Wrapper>
  </>
}

export const ConversationTrends = ({ wrapper: Wrapper = Fragment, preT, data }: { wrapper?: ComponentType<PropsWithChildren<any>>, preT?: string, data: ConversationQuickEvaluation }) => {
  if(!data?.trend?.length) return null

  return (<>
    { preT && <B4Text t={preT} /> }
    <Wrapper><IdeasGraphQuick noRank dotted answerQuickEvaluation={data?.trend} /></Wrapper>
    </>
  )
}

export const ConversationPolarityExtremes = ({data}: {data: ConversationStatisticalEvaluation}) => {
  const sortedResult = data.result.slice().sort((a, b) => b.sigma - a.sigma)
  var topItems: AnswerStatisticalEvaluation[]
  var bottomItems: AnswerStatisticalEvaluation[]
  if (sortedResult.length >= 20) {
    topItems = sortedResult.slice(0, 10)
    bottomItems = sortedResult.slice(-10).reverse()
  } else {
    const middleIndex = Math.floor(sortedResult.length / 2)
    topItems = sortedResult.slice(0, middleIndex)
    bottomItems = sortedResult.slice(-middleIndex).reverse()
  }

  return <>
    <B4Text t="conversationReportPolarityInfo" />
    <B4Text t="conversationReportPolarityHighest" />
    <B4Grid columns={[B4GridColumnType.STRETCH]} cells={[
      ...topItems.flatMap(item => [
        <B4Text>{item.answer}</B4Text>,
      ])
    ]} />

    <B4Text t="conversationReportPolarityLowest" />
    <B4Grid columns={[B4GridColumnType.STRETCH]} cells={[
        ...bottomItems.flatMap(item => [
          <B4Text>{item.answer}</B4Text>,
        ])
      ]} />
  </>
}

export const ConversationPriorityRanking = ({data}: {data: ConversationStatisticalEvaluation}) => {
  const conversationId = useParams<{ conversationId: string }>().conversationId

  if (!data?.available) return null

  return (<>
    <PriorityRankingGraph conversationId={conversationId} answerStatisticalEvaluation={data.result} />
  </>)
}

export const ConversationInnovation = ({data}: {data: ConversationStatisticalEvaluation}) => {
  const conversationId = useParams<{ conversationId: string }>().conversationId

  if (!data?.available) return null

  return (<>
    <PriorityRankingGraph conversationId={conversationId} answerStatisticalEvaluation={data.result.slice(0, 10)} />
  </>)
}

export const ConversationPolarity = ({data}: {data: ConversationStatisticalEvaluation}) => {
  const conversationId = useParams<{ conversationId: string }>().conversationId

  if (!data?.available) return null

  return (<>
    <PolarityGraph conversationId={conversationId} answerStatisticalEvaluation={data.result} />
  </>)
}

export const ConversationCredibleInterval = ({data}: {data: ConversationStatisticalEvaluation}) => {
  const conversationId = useParams<{ conversationId: string }>().conversationId

  if (!data?.available) return null

  return (<>
    <CredibleIntervalGraph conversationId={conversationId} answerStatisticalEvaluation={data.result} />
  </>)
}