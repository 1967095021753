import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { B4H2, B4Layout, B4LayoutSize, B4Size } from "../../design/atomic/layout";
import { createContext, useContext, useEffect, useState } from "react";
import { prefixRoomLink, useRoomNavigate } from "../../utils";
import useConversationsWithStats from "../../hooks/queries/useConversationsWithStats";
import { B4QuestionBox } from "../../design/atomic/question";
import { B4Switcher } from "../../design/atomic/switcher";
import { orderBy } from "lodash";
import { B4StdHeader } from "../common/standard";
import clsx from "clsx";
import { B4ButtonOutlined } from "../../design/atomic/button";
import { B4Color } from "../../design/atomic/consts";
import { B4Text } from "../../design/atomic/text";

export interface ConversationSwitcherChips {
  labelT: string, 
  color?: B4Color
}

export const ConversationSwitcherContext = createContext({
  setTitleT: (titleT: string) => {},
  titleT: '',
  setChips: (filters: ConversationSwitcherChips[]) => {},
  chips: [],
  setChipsIndex: (index: number) => {},
  chipsIndex: -1
});

// Create a hook for easy usage in children
export const useConversationSwitcherContext = () => useContext(ConversationSwitcherContext);

const ConversationSwitcher = () => {
  const navigate = useNavigate()
  const roomNavigate = useRoomNavigate()

  const [titleT, setTitleT] = useState('');
  const [chips, setChips] = useState<ConversationSwitcherChips[]>([]);
  const [chipsIndex, setChipsIndex] = useState(-1);

  const { conversationId } = useParams<{ conversationId: string }>();
  const [sortedConversationsWithStats, setSortedConversationsWithStats] = useState([])
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [subPath, setSubPath] = useState<string>('')

  const {pathname} = useLocation()

  const { data: {conversationsWithStats} = {} } = useConversationsWithStats()

  useEffect(() => {
    if (conversationsWithStats) {
      const sorted = orderBy(conversationsWithStats, ['seq_nr'], ['asc'])
      setSortedConversationsWithStats(sorted)
      setCurrentIndex(sorted.findIndex(c => c.id === conversationId))
    }
  }, [conversationId, conversationsWithStats])

  useEffect(() => {
    const pathOfThisComponent = prefixRoomLink(`/conversation/${conversationId}`)
    const subPath = pathname.substring(pathOfThisComponent.length)
    if (!subPath) {
      // TODO get rid of this once no one relies on the link to the old url
      navigate(pathOfThisComponent + '/dashboard', {replace: true})
    }
    setSubPath(pathname.substring(pathOfThisComponent.length))
  }, [pathname])

  return (
    <ConversationSwitcherContext.Provider value={{ setTitleT, titleT, setChips, chips, setChipsIndex, chipsIndex }}>
      <B4Layout header={<B4StdHeader home />} size={B4LayoutSize.FULL} padding={false}>
        <div className="py-b4-std print:hidden bg-b4-bg-dark"><B4H2 t={titleT} /></div>
        { currentIndex >= 0 &&
          <B4Switcher
            current={currentIndex} onChange={i => roomNavigate(`/conversation/${sortedConversationsWithStats[i].id}${subPath}`, {replace: true}, {keepSearch: true})} elements={sortedConversationsWithStats?.map((conversationWithStats, i) => (
              <B4QuestionBox className={clsx('w-full flex flex-col justify-between')} key={i}
                conversationWithStats={conversationWithStats }
              />
            ))}
            btnWrapper={({children}) => <B4Size className="print:hidden bg-b4-bg-dark">{children}</B4Size>}
            switcherWrapper={({children}) => (
              <B4Size className="print:hidden shadow-md w-full pt-b4-std pb-b4-std-1/2 shadow-b4-bg-dark bg-b4-bg-dark sticky top-b4-header z-10">
                {children}
                <div className="pt-b4-std">
                {
                  chips?.length ? <div className="flex justify-center gap-b4-std-1/2 flex-wrap">{
                    chips.map((chip, i) => (
                      <B4ButtonOutlined key={i} filled={chipsIndex === i} full={false} color={chip.color || B4Color.GREEN} onClick={() => setChipsIndex(i)}><B4Text color={B4Color.INHERIT} t={chip.labelT} /></B4ButtonOutlined>
                    ))
                  }</div> : null
                }
                </div>
              </B4Size>
            )}
        />}
        <div className="pb-b4-std">
          <div className="h-b4-std-1/2 bg-b4-bg-dark print:bg-white" /> {/* hack to have dark padding on white background too */ }
          <Outlet />
        </div>
      </B4Layout>
    </ConversationSwitcherContext.Provider>
  )
}

export default ConversationSwitcher;