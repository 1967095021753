import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_PARTICIPANTS_STATS_QUERY = graphql(/* GraphQL */ `
  query conversationParticipantsStats(
    $conversation_id: ID!
  ) {
    conversationParticipantsStats(id: $conversation_id) {
      name
      total
      categories {
        label
        count
      }
    }
  }
`)

const useConversationParticipantsStats = (options = {}) => useQuery(CONVERSATION_PARTICIPANTS_STATS_QUERY, options);

export default useConversationParticipantsStats;
