import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_ADMIN_TASKS_QUERY = graphql(/* GraphQL */ `
  query conversationAdminTasks(
    $conversation_id: ID!
  ) {
    conversationAdminTasksReport(id: $conversation_id) {
      moved_answers {
        answer {
          answer
        }
        target_conversation
      }
      split_answers {
        answer {
          answer
        }
        split_to {
          answer
        }
      }
      answers_with_duplicates {
        answer {
          answer
        }
        duplicate_of {
          answer
        }
      }
      deleted_answers {
          answer
        }
    }
  }
`)

export const useConversationAdminTasks = (options = {}) => useQuery(CONVERSATION_ADMIN_TASKS_QUERY, {
  ...options,
  fetchPolicy: 'cache-and-network',
});