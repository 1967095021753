/** @jsxImportSource @emotion/react */

import { B4LayoutSize, B4Size } from "../../design/atomic/layout"
import { useConversationSwitcherContext } from "./conversation-switcher";
import { useEffect } from "react";
import { ConversationDeletes, ConversationDuplicates, ConversationMoves, ConversationParticipantsStats, ConversationPolarityExtremes, ConversationPriorityRanking, ConversationSplits, ConversationStats, ConversationTimeline, ConversationTimeStats, ConversationTrends } from "./conversation-reporting-components";
import logo from '../../images/b4LogoSloganBlue.png';
import { useTenant } from "../../hooks";
import { B4Color } from "../../design/atomic/consts";
import { B4Text, B4TextBig, B4TextMedium } from "../../design/atomic/text";
import { useParams } from "react-router-dom";
import useConversation from "../../hooks/queries/useConversation";
import { useConversationStatisticalEvaluation } from "../../hooks/queries/useConversationStatisticalEvaluation";
import { SamplingAlgorithm } from "../../gql/graphql";
import { useConversationAdminTasks } from "../../hooks/queries/useConversationAdminTasks";
import useConversationQuickEvaluation from "../../hooks/queries/useConversationQuickEvaluation";
import { formatDateTime } from "../../design/atomic/utils";
import { css } from "@emotion/react";
import { NetworkStatus } from "@apollo/client";

const ConversationPrint = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { setTitleT } = useConversationSwitcherContext()
  const { data: {conversation: { question }} = {conversation: {}}} = useConversation({variables: {conversation_id: conversationId}})
  const { about_heading, image } = useTenant()

  useEffect(() => {
    setTitleT('lblPrint');
    return () => {
      setTitleT('')
    };
  }, [setTitleT])

  const { data: { conversationQuickEvaluation } = {}, loading: loadingQuick } = useConversationQuickEvaluation({ variables: { conversation_id: conversationId } })
  const { data: { conversationStatisticalEvaluation: conversationStatisticalEvaluationAlgo1 } = {}, networkStatus: networkStatusAlgo1 } = useConversationStatisticalEvaluation({ variables: { conversation_id: conversationId, algorithm: SamplingAlgorithm.Algo1 } })
  const { data: { conversationStatisticalEvaluation: conversationStatisticalEvaluationCombined } = {}, networkStatus: networkStatusCombined } = useConversationStatisticalEvaluation({ variables: { conversation_id: conversationId, algorithm: SamplingAlgorithm.Combined } })
  const { data: { conversationAdminTasksReport } = {}, loading: loadingAdminTasks} = useConversationAdminTasks({variables: {conversation_id: conversationId}})

  if (loadingQuick || networkStatusAlgo1 === NetworkStatus.loading || networkStatusCombined === NetworkStatus.loading || loadingAdminTasks) return null

  if (!conversationQuickEvaluation?.available &&!conversationStatisticalEvaluationAlgo1?.available) return (
    <B4Size size={B4LayoutSize.A4} className="py-b4-std">
      <B4Text t="conversationReportNoIdeasReady" />
    </B4Size>
  )

  if (conversationStatisticalEvaluationAlgo1?.in_progress || conversationStatisticalEvaluationCombined?.in_progress) return (
    <B4Size size={B4LayoutSize.A4} className="py-b4-std">
      <B4Text t="conversationReportInProgress" />
    </B4Size>
  )

  return (
      <B4Size size={B4LayoutSize.A4} className="py-b4-std"><div className="space-y-b4-std-2" css={css`
        @page {
          @top-center {
            content: "BrainE4 - ${about_heading}";
          }
        }
        @page:first {
          @top-center {
            content: "";
          }
        }
      `}>
        <div className="flex flex-col gap-b4-std-2 justify-center items-center print:break-after-page">
          <img className="max-w-full w-16" src={logo} alt='' />
          <B4TextMedium t="conversationPrintDetailedReport" color={B4Color.BLUE_DARK} />
          <B4Text>{formatDateTime(conversationStatisticalEvaluationCombined?.generated_at)}</B4Text>
          <B4TextBig html={about_heading} color={B4Color.BLUE_DARK} />
          { image && <img className="max-w-full w-24" src={image} alt='' /> }
          <div className="space-y-b4-std">
            <B4TextMedium className="text-center" t="conversationPrintQuestion" color={B4Color.BLUE_DARK} />
            <B4TextMedium className="italic text-center" html={question} color={B4Color.BLUE_DARK} />
          </div>
        </div>
        <ConversationPriorityRanking data={conversationStatisticalEvaluationAlgo1} />
        <ConversationTrends data={conversationQuickEvaluation} />
        <div className="grid grid-cols-2 gap-b4-std-2 items-start">
          <ConversationStats />
          <ConversationTimeline />
          <ConversationTimeStats />
          <ConversationParticipantsStats />
        </div>
        <ConversationDuplicates data={conversationAdminTasksReport} tPre="conversationReportDuplicatesInfo" />
        <ConversationSplits data={conversationAdminTasksReport} tPre="conversationReportSplitIdeasInfo" />
        <ConversationMoves data={conversationAdminTasksReport} tPre="conversationReportMovedIdeasInfo" />
        <ConversationDeletes data={conversationAdminTasksReport} tPre="conversationReportAnswersWereDeleted" />
        <ConversationPolarityExtremes data={conversationStatisticalEvaluationCombined} />
      </div></B4Size>
  )
}

export default ConversationPrint;