import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_QUICK_EVALUATION_QUERY = graphql(/* GraphQL */ `
  query conversationQuickEvaluation(
    $conversation_id: ID!
  ) {
    conversationQuickEvaluation(id: $conversation_id) {
      available
      evaluated {
        id
        is_seed
        answer
        naive_score
        rank
        upvotes
        downvotes
        participants
      }
      trend {
        id
        is_seed
        answer
        naive_score
        rank
        upvotes
        downvotes
        participants
      }
    }
  }
`)

const useConversationQuickEvaluation = (options = {}) => useQuery(CONVERSATION_QUICK_EVALUATION_QUERY, {
  ...options,
  fetchPolicy: 'cache-and-network',
});

export default useConversationQuickEvaluation;
