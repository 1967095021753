export enum B4Color {
  INHERIT,
  BLUE_DARK,
  BLUE,
  BLUE_LIGHT,
  GREEN,
  GREEN_NEON,
  RED,
  WHITE,
  GREY,
  ORANGE
}