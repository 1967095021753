import clsx from "clsx";
import { B4IconButtonNoP } from "./button";
import { MdClose } from "react-icons/md";
import { B4Color } from "./consts";


export interface B4ChipProps {
  color?: B4Color,
  disabled?: boolean,
  children: React.ReactNode,
  className?: string,
  onClose?: (e: React.MouseEvent<HTMLElement>) => void,
  filled?: boolean
}


export const B4Chip = ({children, color = B4Color.BLUE_DARK, disabled = false, className = null, onClose = null, filled = false}: B4ChipProps) => {
  return (
    <div className={clsx('rounded-full pl-2', {
      'pr-2': !onClose || disabled,
      'flex items-center': !!onClose && !disabled,
      'border': !disabled,
      'bg-[#DADADA]': disabled,
      'border-b4-primary': B4Color.BLUE_DARK === color,
      'bg-b4-primary': B4Color.BLUE_DARK === color && filled,
      'border-b4-red': B4Color.RED === color,
      'bg-b4-red': B4Color.RED === color && filled,
      'border-b4-title': B4Color.BLUE === color,
      'bg-b4-title': B4Color.BLUE === color && filled,
      'border-b4-secondary': B4Color.GREEN_NEON === color,
      'bg-b4-secondary': B4Color.GREEN_NEON === color && filled,
      'border-b4-mine': B4Color.GREEN === color,
      'bg-b4-mine': B4Color.GREEN === color && filled,
      'border-b4-orange': B4Color.ORANGE === color,
      'bg-b4-orange': B4Color.ORANGE === color && filled,
    }, className)}>
      <div className="py-1">{children}</div>
      { (onClose && !disabled) ? <B4IconButtonNoP onClick={onClose} className="p-1"><MdClose className="text-sm"/></B4IconButtonNoP> : null }
    </div>
  )
}