import { isDate, isNumber } from "lodash";
import { createContext, DependencyList, EffectCallback, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import i18n from "../../_i18n/i18n";

export const useKeyUp = (targetKey, callback) => {
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (callback && event.key === targetKey) {
        callback();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [targetKey, callback]);
};

export const useChangedEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
      if (didMount.current) effect();
      else didMount.current = true;
  }, deps);
}

export const useScrollLock = (enabled = true) => {
  useEffect(() => {
    const handleScroll = (event) => {
      if (!enabled) {
        event.preventDefault();
      }
    };

    if (!enabled) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('scroll', handleScroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('scroll', handleScroll);
    };
  }, [enabled]);
};

export const useScrollYMoreThen = (position: number) => {
  const [moreThenPosition, setMoreThenPosition] = useState(false)

  useEffect(() => {

    const handleScroll = () => {
      setMoreThenPosition(window.scrollY > position)
    }

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, []) // Empty dependency array ensures the effect runs only once when the component mounts

  return moreThenPosition
}

export const useScrollY = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0);

  useEffect(() => {
    let ticking = false;

    const updatePosition = () => {
      setScrollYPosition(window.scrollY);
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updatePosition);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);
    
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollYPosition;
};

export const useIsBig = () => {
  const isBig = useMediaQuery('(min-width: 768px), print')

  return isBig
}

export const scrollYTo = (location: number|MutableRefObject<any>, smooth: boolean) => {
  const locationAsNumber = isNumber(location) ? location :
    window.scrollY + (location as unknown as MutableRefObject<any>).current.getBoundingClientRect().top 
    - 58 // This is the height of the header in pixels. Needs to be substracted to position correctly

  if (smooth) {
    window.scrollTo({
      top: locationAsNumber,
      behavior: 'smooth'
    })
  } else {
    window.scrollTo(0, locationAsNumber)
  }
}

export enum B4BackgroundType {
  DARK,
  LIGHT
}

interface B4BackgroundContextProps {
  bgType: B4BackgroundType,
}

export const B4BackgroundContext = createContext<Partial<B4BackgroundContextProps>>({bgType: B4BackgroundType.DARK})
export const useB4BackgroundContextContext = () => useContext(B4BackgroundContext);


const getFormattingLocale = () => {
  try {
    // Get system locale from navigator
    const systemLocale = navigator.language
    
    // Extract the country code from system locale (e.g., 'en-US' -> 'US')
    const countryCode = systemLocale.split('-')[1];
    
    if (!countryCode) {
      // If no country code in system locale, just use the language
      return i18n.language;
    }
    
    // Combine the user's language choice with the system country code
    return `${i18n.language}-${countryCode}`;
  } catch (error) {
    // Fallback to just using the language if there's any error
    console.warn('Error constructing locale, falling back to language only:', error);
    return i18n.language;
  }
}

export const formatNumber = (number?: number, decimalPlaces = 0): string => {
	if (number === null || number === undefined) {
		return ''
	}
	const numberFormat = new Intl.NumberFormat(getFormattingLocale(), {
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalPlaces,
	});
	return numberFormat.format(number);
};

export const formatDate = (date?: string|Date): string => {
  if (!date) {
    return ''
  }

  return (isDate(date) ? date : new Date(date)).toLocaleDateString(getFormattingLocale())
}

export const formatTime = (date?: string|Date): string => {
  if (!date) {
    return ''
  }

  return (isDate(date) ? date : new Date(date)).toLocaleTimeString(getFormattingLocale())
}

export const formatDateTime = (date?: string|Date): string => {
  if (!date) {
    return ''
  }

  return (isDate(date) ? date : new Date(date)).toLocaleString(getFormattingLocale())
}

export const useKeyPress = (key: string) => {
  const [isMetaPressed, setIsMetaPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === key) {
        setIsMetaPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === key) {
        setIsMetaPressed(false);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsMetaPressed(false);
      }
    };

    const handleBlur = () => {
      setIsMetaPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('blur', handleBlur);
    };
  }, []);

  return isMetaPressed;
};

export const useAltKeyPress = () => useKeyPress('Alt');