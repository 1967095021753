import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_TIMELINE_QUERY = graphql(/* GraphQL */ `
  query conversationTimeline(
    $conversation_id: ID!
  ) {
    conversationTimeline(id: $conversation_id) {
      max_answers
      max_votes
      max_skips
      max_battles
      events {
        label
        answers
        votes
        skips
        battles
      }
    }
  }
`)

const useConversationTimeline = (options = {}) => useQuery(CONVERSATION_TIMELINE_QUERY, options);

export default useConversationTimeline;
