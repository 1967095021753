import { B4IconButton } from "./button"
import { MdAdd, MdEdit } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { B4SpaceVertical } from "./layout"
import { B4Bubble, B4Corner } from "./bubble"
import { B4TextInput } from "./input/text"
import { useState } from "react"
import { B4Color } from "./consts"
import { B4TextSmall } from "./text"
import { formatNumber } from "./utils"
import { isString } from "lodash"
import clsx from "clsx"

export interface B4BubbleListFooterElementProps {
  label: React.ReactNode|string,
  value?: number|string
}

export const B4BubbleListFooterElement = ({label, value = null}: {label: React.ReactNode|string, value?: number|string}) => value === null ? null : (
  <div className="flex items-center space-x-b4-std-1/2">
    <B4TextSmall noWordBreak className="text-nowrap">{label}{isString(label) && ':'}</B4TextSmall>
    <B4TextSmall noWordBreak className="text-nowrap">{typeof value === 'number' ? formatNumber(value) : value}</B4TextSmall>
  </div>
)

interface B4BubbleListProps<T> {
  render: (item: T) => React.ReactNode,
  renderRelative?: (item: T) => React.ReactNode,
  renderFooter?: (item: T) => React.ReactNode,
  genClassName?: (item: T) => string,
  onClick?: (e: React.MouseEvent<HTMLElement>, element: T) => void,
  onCreate?: () => void,
  edit?: {
    onEdit: (element: T) => void,
    enabled?: (element: T) => boolean
  },
  data: T[],
  searchProps?: string[],
  sharpCorner?: B4Corner
}

export const B4BubbleList = <T,>({
    onClick = null, data, render, renderFooter = null, searchProps = null, sharpCorner = null,
    edit = null, 
    onCreate = null, 
    genClassName = null,
    renderRelative = null
  }: B4BubbleListProps<T>) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  if (!data) return null

  const { onEdit, enabled: editEnabled = () => true } = edit || { onEdit: null, enabled: () => true };

  return (<B4SpaceVertical>

    { searchProps &&
      <div className="grow">{ searchProps && <B4TextInput placeholder={t('txtSearch')} value={search} onChange={value => setSearch(value)} />}</div>
    }
    {
      data.filter(element => !searchProps || searchProps.some(searchProp => element[searchProp]?.toLowerCase().includes(search.toLowerCase())))
      .map((element, index) => {
        return (
          <div key={index} className={clsx({'relative': renderRelative})}>
            <B4Bubble className={genClassName && genClassName(element)} onClick={e => onClick && onClick(e, element)} sharpCorner={
                sharpCorner ||
                (index % 3 === 1 && B4Corner.TOP_RIGHT) ||
                (index % 3 === 2 && B4Corner.BOTTOM_RIGHT) ||
                B4Corner.TOP_LEFT
              }
            >
              {render(element)}
              <div className="flex items-end space-x-b4-std-1/2">
                <div className="grow">{renderFooter && renderFooter(element)}</div>
                {onEdit && editEnabled(element) && <B4IconButton color={B4Color.GREEN} onClick={e => {e.stopPropagation(); onEdit(element)}}><MdEdit className="text-base" /></B4IconButton>}
              </div>
            </B4Bubble>
            { renderRelative && renderRelative(element) }
          </div>
        )
      })
    }
    { onCreate && <div className="flex justify-center sticky bottom-b4-std"><B4IconButton big className="shadow-md shadow-b4-bg-dark" color={B4Color.GREEN} full={false} onClick={onCreate}><MdAdd /></B4IconButton></div> }
  </B4SpaceVertical>)
}