import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const CONVERSATION_STATISTICAL_EVALUATION_QUERY = graphql(/* GraphQL */ `
  query conversationStatisticalEvaluation(
    $conversation_id: ID!
    $algorithm: SamplingAlgorithm!
  ) {
    conversationStatisticalEvaluation(id: $conversation_id, algorithm: $algorithm) {
      in_progress
      generated_at
      available
      average_credible_interval
      result {
        id
        answer_id
        is_seed
        answer
        rank
        naive_score
        median
        sigma
        credible_interval
        error_tolerance
      }
    }
  }
`)

export const useConversationStatisticalEvaluation = (options = {}) => {
  const res = useQuery(CONVERSATION_STATISTICAL_EVALUATION_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true, // Without it the onCompleted is not called on the polling calls. See https://github.com/apollographql/apollo-client/issues/5531
    onCompleted(data) {
      if (data.conversationStatisticalEvaluation.in_progress) {
        res.startPolling(10000)
      } else {
        res.stopPolling()
      }
    }
  })
  return res;
};