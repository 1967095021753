import React, { Fragment } from "react"
import { B4RulerHorizontal, B4RulerVertical } from "./layout"
import { B4Text, B4TextSmall, B4TextTiny } from "./text"
import { B4Color } from "./consts"
import clsx from "clsx"
import { isBoolean, isNumber, isString } from "lodash"
import { formatNumber } from "./utils"
import { useTranslation } from "react-i18next"

export enum B4GridColumnType {
  AUTO = 'auto',
  STRETCH = '1fr'
}

interface B4TableProps {
  headerRows?: React.ReactNode[],
  footerRows?: React.ReactNode[],
  rows:  React.ReactNode[],
  columns: B4GridColumnType[]
}

interface B4TableCellProps {
  children?: React.ReactNode,
  className?: string,
  colSpan?: number
}

export const B4TableHeader = ({children, className, colSpan}: B4TableCellProps) => {
  return (
    <th className={clsx('p-b4-std-1/8', className)} colSpan={colSpan}>
      { children } 
    </th>
  )
}

export const B4TableData = ({children, className, colSpan}: B4TableCellProps) => {
  return (
    <td className={clsx('p-b4-std-1/8', className)} colSpan={colSpan}>
      { children } 
    </td>
  )
}

export const B4TableRow = ({children}: {children: React.ReactNode}) => {
  return (
    <tr className="print:break-inside-avoid">
      { children }
    </tr>
  )
}

export const B4Table = ({headerRows, footerRows, rows, columns}: B4TableProps) => {
  const noOfStretchColumns = columns.filter(c => c === B4GridColumnType.STRETCH).length
  const percentage = 100 / noOfStretchColumns - 0.1
  return (
    <table className="border-separate -m-b4-std-1/8 width-full">
      <colgroup>{
        columns.map((c, i) => (
          <col key={i} style={{width: c === B4GridColumnType.STRETCH ? `${percentage}%` : '0.1%', whiteSpace: c === B4GridColumnType.STRETCH ? 'nowrap' : 'normal'}} />
        ))
      }
      </colgroup>
      <thead className="">
        { headerRows?.map((h, i) => <Fragment key={i}>{h}</Fragment>) }
      </thead>
      <tbody>
        { rows?.map((r, i) => <Fragment key={i}>{r}</Fragment>) }
      </tbody>
      <tfoot>
        { footerRows.map((h, i) => <Fragment key={i}>{h}</Fragment>) }
      </tfoot>
    </table>
  )
}

interface B4GridProps {
  columns: B4GridColumnType[],
  cells:  React.ReactNode[],
  rulersVertical?: boolean,
  rulersHorizontal?: boolean,
}

export const B4Grid = ({columns, cells, rulersVertical = false, rulersHorizontal = true}: B4GridProps) => {

  return (
    <div className={clsx('grid gap-y-b4-std-1/2 items-center', {
      'gap-x-b4-std': rulersVertical,
      'gap-x-b4-std-2': !rulersVertical
    })} style={{
      gridTemplateColumns: columns.filter(c => c).join(rulersVertical ? ' auto ' : ' '),
    }}>
      {
        cells.filter(c => c).map((cell: React.ReactNode, i) => (
          <Fragment key={i}>
            { cell }
            { rulersHorizontal && ((i + 1) % columns.length === 0) && <B4RulerHorizontal color={B4Color.BLUE_DARK} className="col-span-full" /> }
            { rulersVertical && ((i + 1) % columns.length !== 0) && <B4RulerVertical color={B4Color.BLUE_DARK}  className="self-stretch" /> }
          </Fragment>
        ))
      }
    </div>
  )
}

export interface B4ListLabelValue {
  label: string, 
  emp?: boolean,
  value: React.ReactNode|string,
  icon?: React.ReactNode,
  indented?: boolean,
  percentage?: boolean,
}
interface B4ListProps {
  items: B4ListLabelValue[],
  centralised?: boolean
}

const B4ListValue = ({html, indented, color, className}: {html: string, indented?: boolean, color?: B4Color, className?: string}) => (
  indented ? <B4TextSmall html={html} className={className} color={color} /> : <B4Text html={html} className={className} color={color} />
)

export const B4List = ({items, centralised = true}: B4ListProps) => {
  const { t } = useTranslation()
  const classNameValue = clsx({'text-right': !centralised})
  return (
    <B4Grid 
      rulersVertical={centralised}
      columns={[B4GridColumnType.STRETCH, centralised ? B4GridColumnType.STRETCH : B4GridColumnType.AUTO]} 
      cells={items.map(({label, value, emp, icon, indented: idented = false, percentage = false }) => {
        const color = emp ? B4Color.BLUE : B4Color.BLUE_DARK
        return ([
          <div className={clsx('flex items-center space-x-b4-std-1/2', {
            'justify-end': centralised,
            'pl-b4-std-2': idented
          })}>
            {icon && (idented ? <B4TextTiny color={color}>{icon}</B4TextTiny> : <B4TextSmall color={color}>{icon}</B4TextSmall>)}
            {idented ? <B4TextTiny color={color}>{label}:</B4TextTiny> : <B4TextSmall color={color}>{label}:</B4TextSmall> }
          </div>,
          ((value === null || value === undefined) && <div/>) ||
          (isBoolean(value) && <B4ListValue indented={idented} color={color} className={classNameValue} html={(value ? t('txtYes') : t('txtNo'))} />) ||
          (isString(value) && <B4ListValue indented={idented} color={color} className={classNameValue} html={value } />) ||  
          (isNumber(value) && <B4ListValue indented={idented} color={color} className={classNameValue} html={formatNumber(value * (percentage ? 100 : 1), 1) + (percentage ? '%' : '')} />) || 
          value]
        )
      }).flat()} 
    />
  )
}